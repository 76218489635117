import CryptoJS from "crypto-js";
const keyString = "bW9uZXRAMTIz";
const ivString = "paysys12";
const keyBytes = CryptoJS.MD5(CryptoJS.enc.Base64.parse(keyString));
const keyHex = CryptoJS.enc.Hex.parse(
  keyBytes.toString(CryptoJS.enc.Hex) +
    keyBytes.toString(CryptoJS.enc.Hex).substring(0, 16)
);
const ivHex = CryptoJS.enc.Hex.parse(
  CryptoJS.enc.Utf8.parse(ivString).toString(CryptoJS.enc.Hex)
);
export const encrypt = (value) => {
  try {
    const encrypted = CryptoJS.TripleDES.encrypt(value, keyHex, {
      iv: ivHex,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  } catch (e) {
    console.error("Encryption error:", e);
    return null;
  }
};
export const decrypt = (value) => {
  try {
    const decrypted = CryptoJS.TripleDES.decrypt(value, keyHex, {
      iv: ivHex,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (e) {
    console.error("Decryption error:", e);
    return null;
  }
};
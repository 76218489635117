// GET /api/v1/my/accounts

import axios from "axios";
// import config from "../configfile";
import { useMutation, useQuery } from "react-query";
import queryClient from "./queryClient";
import axiosInstance from "./axiosInstance";
import { encryptedUrl } from "./EncrypteUrl";
import { consoleLog } from "../helper/consoleLogs";

const GetAccounts = async (token: any) => {
  const headers = { "X-Auth-Token": token };
  return axiosInstance.get(
    encryptedUrl + `/api/v1/my/accounts?channel=INTERNET_BANKING`,
    { headers }
  );
};

export const GetAccountsData = (token: any) => {
  return useQuery("accountsData", () => GetAccounts(token), {
    staleTime: Infinity,
    onSuccess: (data) => {
      consoleLog("accounts data in api", data?.headers["x-auth-next-token"]);
      queryClient.setQueryData("token", data?.headers["x-auth-next-token"]);

      queryClient.setQueryData("accountsData", data);
    },
    enabled: false,
    cacheTime: 500,
  });
};

export const GetAccountsList = (): any => {
  return queryClient.getQueryData("accountsData");
};

// START POST /api/v1/my/logout

const PostLogout = async (user: { token: any }) => {
  const headers = { "X-Auth-Token": user.token };

  return axiosInstance.post(
    encryptedUrl + "/api/v1/my/logout?channel=INTERNET_BANKING",
    null,
    { headers }
  );
};

export const PostLogoutData = () => {
  return useMutation(PostLogout, {
    onSuccess: (data) => {
      consoleLog("post logout in api", data);
      consoleLog("token in logout headers", data?.headers["x-auth-next-token"]);
      // queryClient.setQueryData("token", data?.headers["x-auth-next-token"]);
    },
  });
};

// END POST /api/v1/my/logout

// GET /api/v1/my/overview-without-balance
const GetAccountWithoutBalance = async (token: any) => {
  const headers = { "X-Auth-Token": token };
  return axiosInstance.get(
    encryptedUrl +
      `/api/v1/my/overview-without-balance?channel=INTERNET_BANKING`,
    { headers }
  );
};

export const GetAccountWithoutBalanceData = (token: any) => {
  return useQuery(
    "overview-without-balance-data",
    () => GetAccountWithoutBalance(token),
    {
      onSuccess: (data) => {
        consoleLog("overview-without-balance-data in api", data);
        queryClient.setQueryData("token", data?.headers["x-auth-next-token"]);
        consoleLog("cnic", data.data?.data?.accounts?.cnic);
        queryClient.setQueryData("cnic", data.data?.data?.accounts?.cnic);
        queryClient.setQueryData("iban", data.data?.data?.accounts?.iban);
      },
      enabled: false,
    }
  );
};

// GET /api/v1/raast/payment/getbanklist
const GetBanksList = async (token: any) => {
  const headers = { "X-Auth-Token": token };
  return axiosInstance.get(
    encryptedUrl + `/api/v1/raast/payment/getbanklist?channel=INTERNET_BANKING`,
    { headers }
  );
};

export const GetBanksListData = (token: any) => {
  return useQuery("get-banks-list", () => GetBanksList(token), {
    onSuccess: (data) => {
      consoleLog("get banks list in api", data);
      queryClient.setQueryData("token", data?.headers["x-auth-next-token"]);
    },
    enabled: false,
  });
};

export const GetCnicData = (): any => {
  const cnicData = queryClient.getQueryData("cnic");
  return cnicData;
};
export const GetIbanData = (): any => {
  return queryClient.getQueryData("iban");
};

// /api/v1/bill/getBillersList/{billerType}/{pageSize}/{pageNumber}

const GetBillersList = async (
  billerType: string,
  pageSize: string,
  pageNumber: string,
  token: any
) => {
  const headers = { "X-Auth-Token": token };
  return axiosInstance.get(
    encryptedUrl +
      `/api/v1/bill/getBillersList/${billerType}/${pageSize}/${pageNumber}?channel=INTERNET_BANKING`,
    { headers }
  );
};

export const GetBillersListData = (
  billerType: string,
  pageSize: string,
  pageNumber: string,
  token: any
) => {
  return useQuery(
    "get-billers-list",
    () => GetBillersList(billerType, pageSize, pageNumber, token),
    {
      onSuccess: (data) => {
        consoleLog("get billers list in api", data);
        queryClient.setQueryData("token", data?.headers["x-auth-next-token"]);
      },
      enabled: false,
      cacheTime: 50,
    }
  );
};

import React from "react";
import image from "../../stories/assets/images/image.png";
import { ImageContainer } from "./styles";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./carousel.css";

export const ImageCarousel = () => {
  const images = [
    "/assets/DAO 1.jpeg",
    "/assets/Gold.jfif",
    "/assets/Digi Rev.jfif",
    "/assets/Izafi Munafa.jfif",
    "/assets/RAAST.jfif",
    "/assets/Choice.jfif",
  ];

  return (
    <ImageContainer>
      <Carousel
        autoPlay={true}
        interval={3000}
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        infiniteLoop={true}
        stopOnHover={false}
      >
        {images.map((img, index) => (
          <div key={index}>
            <img
              src={img}
              alt={`carousel-img-${index}`}
              width={200}
              height={375}
            />
          </div>
        ))}
      </Carousel>
    </ImageContainer>
  );
};
export default ImageCarousel;

// import { Link } from "react-router-dom";
import styled from "styled-components";
// export const SidebarContainer = styled.div`
//   flex: 1;
//   width: 270px;
//   height: 100vh;
//   padding: 22px 24px 20px 0px;
//   //   background-color: #0d1821;
//   background-color: white;
// `;
export const SidebarNav = styled.div`
  background-color: #0d1821;
  padding: 1%;
  overflow-y: hidden;
  transition: 350ms;
  position: fixed;
  top: 0;
  bottom: 0; /* Take full height */
  left: 0;
  width: 250px; /* Adjust width as needed */
  z-index: 1000; /* Adjust the z-index to position it correctly */
  /* Add other styles if necessary */
`;

export const SidebarWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: space-between;
  padding-top: 10px;
  position: sticky;
  top: 0;
  height: 87vh;
  overflow-y: scroll;
  // max-height : 200px fixed !important;
  ::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
    border: 7px solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: grey;
  }
`;

export const SidebarItem = styled.div`
  // width: 108%;
  border-radius: 2px;
  cursor: pointer;
  font-family: FontDemiBold;
  font-size: 12px;
`;

export const SidebarItemIcons = styled.div`
  font-size: 18px;
  font-weight: normal;
  color: #fff;
  align
`;

export const MenuItem = styled.div`
  padding: 0.2rem;
  font-family: FontRegular !important;
  font-size: 12px;
`;

// export const MenuLink = styled(Link)`
//   width: 100px;
//   padding-left: 3rem;
//   font-family: FontRegular;
//   font-size: 12px;
//   font-weight: 600;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   // text-decoration:none;
//   letter-spacing: normal;
//   color: #fff;
//   &:hover {
//     color: #439758;
//     cursor: pointer;
//   }
// `;

export const MenuLink = styled.div<{
  active?: boolean;
}>`
  width: 100%;
  padding-left: 3rem;
  font-family: FontRegular;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-decoration: none;
  letter-spacing: normal;
  color: ${({ active }) => (active ? " #439758" : "#fff")};
  &:hover {
    color: #439758;
    cursor: pointer;
  }
`;

export const Oval = styled.div`
  width: 25px;
  height: 25px;
  padding: 16px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.09);
  margin-right: 6px;
  // background-image: linear-gradient(to right, #439758, #75fb93);
  background-color: #3d464d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 11px;
`;

export const InnerDiv = styled.div`
  border-radius: 4px;
  // background-color: #eceded;
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: #fff;
  position: fixed;
  bottom: 7.5px; /* Adjust as needed */
  left: 15px; /* Adjust as needed */
  z-index: 999; /* Adjust as needed */
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
// export const SidebarLabel = styled.span`
//   margin-left: 16px;
// `;

// export const DropdownLink = styled(Link)`
//   background: #252831;
//   height: 60px;
//   padding-left: 3rem;
//   display: flex;
//   align-items: center;
//   text-decoration: none;
//   color: #f5f5f5;
//   font-size: 18px;

//   &:hover {
//     background: green;
//     cursor: pointer;
//   }
// `;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  // padding-top: 10%;
`;

export const Logo = styled.img`
  max-width: 100%;
  height: auto;
`;

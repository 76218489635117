import React, { useEffect, useState } from "react";
import {
  NavbarContainer,
  Logo,
  LogoContainer,
  LinksContainer,
  LinkItem,
  ActualLink,
  NavDiv,
  ExternalLink,
} from "./styles";
import { NavbarProps } from "./types";
import { MdOutlineLogout } from "react-icons/md";
import { GetTokenData } from "../../Api/Token";
import { PostLogoutData } from "../../Api/GeneralApi";
import { useNavigate } from "react-router-dom";
import LogoutModal from "../../components/LogoutModal";
import queryClient from "../../Api/queryClient";
import LoaderModal from "../../components/LoaderModal";
import MenuIcon from "../../components/Menu/MenuIcon";
import { Collapse, FormControlLabel, Grid, useMediaQuery } from "@mui/material";
import { useQueryClient } from "react-query";
import AlertModal from "../../components/AlertModal";
import { consoleLog } from "../../helper/consoleLogs";

const Navbar = ({ logoSrc, links, variant, onMenuIconClick }: NavbarProps) => {
  const tokenData = GetTokenData();
  const [inactiveModal, setInactiveModal] = useState(false);
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const ReactQueryClient = useQueryClient();

  const {
    mutate: LogoutDataMutate,
    isLoading: LogoutDataIsLoading,
    isError: LogoutDataIsError,
    data: LogoutData,
    error: LogoutDataError,
  } = PostLogoutData();

  useEffect(() => {
    if (LogoutData?.data?.responseCode) {
      queryClient.clear();
      ReactQueryClient.clear();
      consoleLog("LogoutDataData in navbar component", LogoutData);
      navigate("/login");
    }
  }, [LogoutData]);

  useEffect(() => {
    if (LogoutDataIsError) {
      consoleLog("LogoutDataIsError", LogoutDataError);
    }
  }, [LogoutDataIsError, LogoutDataError]);

  useEffect(() => {
    let inactivityTimer;

    const showModal = () => {
      setInactiveModal(true);
    };

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        // showModal();
      }, 300000); // 5 minutes (adjust this time as needed)
    };

    // Event listeners to reset the timer when the user interacts with the page
    document.addEventListener("mousemove", resetInactivityTimer);
    document.addEventListener("keydown", resetInactivityTimer);

    // Initial timer start
    resetInactivityTimer();

    // Clean up event listeners when the component unmounts
    return () => {
      document.removeEventListener("mousemove", resetInactivityTimer);
      document.removeEventListener("keydown", resetInactivityTimer);
    };
  }, []);

  const handleLogout = () => {
    const tokenData = GetTokenData();
    if (tokenData) {
      LogoutDataMutate({ token: tokenData });
    } else {
      queryClient.clear();
      ReactQueryClient.clear();
      navigate("/login");
    }
  };

  const isMobile = useMediaQuery("(max-width:1024px)");

  return (
    <NavbarContainer variant={variant}>
      <LoaderModal open={LogoutDataIsLoading} />
      <AlertModal
        responseCode="00"
        open={inactiveModal}
        setSuccessSubmit={handleLogout}
      >
        Dear Customer, You are inactive for too long. Please Login again
      </AlertModal>
      <LogoutModal
        open={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        setSuccessSubmit={handleLogout}
      >
        Are you sure you want to logout?
      </LogoutModal>
      {variant === "Primary" ? (
        <>
          <NavDiv isMobile={isMobile}>
            <div>{isMobile ? <MenuIcon onClick={onMenuIconClick} /> : ""}</div>
            <LinksContainer isMobile={isMobile}>
              <LinkItem isMobile={isMobile}>
                <ActualLink
                  to="/faqs"
                  style={{
                    fontFamily: "FontRegular",
                    fontWeight: "normal",
                    fontSize: "12px",
                  }}
                >
                  FAQs
                </ActualLink>
              </LinkItem>
              <LinkItem isMobile={isMobile}>
                <ActualLink
                  to="/term-and-condition"
                  style={{
                    fontFamily: "FontRegular",
                    fontWeight: "normal",
                    fontSize: "12px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Terms and Conditons
                </ActualLink>
              </LinkItem>
              <LinkItem isMobile={isMobile}>
                <ActualLink
                  to="/contact-us"
                  style={{
                    fontFamily: "FontRegular",
                    fontWeight: "normal",
                    fontSize: "12px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Contact Us
                </ActualLink>
              </LinkItem>

              <LinkItem
                onClick={() => setShowLogoutModal(true)}
                style={{
                  fontFamily: "FontRegular",
                  fontWeight: "normal",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
              >
                Logout
              </LinkItem>
            </LinksContainer>
          </NavDiv>
        </>
      ) : variant === "Secondary" ? (
        <LogoContainer variant={variant}>
          <Logo src={logoSrc} />
        </LogoContainer>
      ) : (
        ""
      )}
    </NavbarContainer>
  );
};

export default Navbar;

// import React from "react";
// import { Title } from "../Title";
// import { CapsuleContainer, CapsuleIcon, CapsuleTitle } from "./styles";
// import { CapsuleProps } from "./types";
// import { Link } from "react-router-dom";

// export const Capsule = ({
//   title,
//   leftIcon,
//   rightIcon,
//   path,
//   variant,
// }: CapsuleProps) => {
//   // consoleLog("capsule path", path);
//   return (
//     <CapsuleContainer to={path || ""} variant={variant}>
//       {leftIcon && <CapsuleIcon>{leftIcon}</CapsuleIcon>}
//       <CapsuleTitle variant={variant}>
//         <Title TitleText={title} role="p2" />
//       </CapsuleTitle>
//       {rightIcon && <CapsuleIcon>{rightIcon}</CapsuleIcon>}
//     </CapsuleContainer>
//     // </Link>
//   );
// };

import React from "react";
import { Title } from "../Title";
import {
  CapsuleContainer,
  CapsuleDiv,
  CapsuleIcon,
  CapsuleTitle,
} from "./styles";
import { CapsuleProps } from "./types";
import { Link, useLocation } from "react-router-dom";
export const Capsule = ({
  title,
  leftIcon,
  rightIcon,
  path,
  variant,
  subMenu,
  icon,
}: CapsuleProps) => {
  const location = useLocation();
  const isActive =
    path === location.pathname ||
    (subMenu && subMenu.some((item: any) => item.path === location.pathname));
  // consoleLog(
  //   "is Active in capsule",
  //   isActive,
  //   path,
  //   location.pathname,
  //   subMenu
  // );
  // consoleLog("icon", icon);

  return (
    // <Link to={path || ""}>
    <>
      {path ? (
        <CapsuleContainer to={path} variant={variant} active={isActive}>
          <img src={icon} alt="icon" height={22} width={22} />
          <CapsuleTitle variant={variant}>
            <Title TitleText={title} role="p4" />
          </CapsuleTitle>
          {rightIcon && <CapsuleIcon>{rightIcon}</CapsuleIcon>}
        </CapsuleContainer>
      ) : (
        <CapsuleDiv variant={variant} active={isActive}>
          <img src={icon} alt="" height={22} width={22} />
          <CapsuleTitle variant={variant}>
            <Title TitleText={title} role="p4" />
          </CapsuleTitle>
          {rightIcon && <CapsuleIcon>{rightIcon}</CapsuleIcon>}
        </CapsuleDiv>
      )}
    </>
    // </Link>
  );
};

import axios, { AxiosResponse, AxiosError } from 'axios';
import type { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'
import configObject from '../config';

//instance of Axios
const axiosInstance = axios.create();
const enableLogging=false;

// const appendLogs = (logs: any[]) => {
//   const existingLogs = JSON.parse(localStorage.getItem('apiLogs') )|| [];
//   const updatedLogs = [...existingLogs, ...logs];
//   localStorage.setItem('apiLogs', JSON.stringify(updatedLogs));
// }
// request interceptor
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    //request
    // const requestLog = {
    //   timestamp: new Date().toISOString(),
    //   method: config.method,
    //   url: config.url as string,
    //   data: config.data as any,
    // };
   

    // let existingLogs : any[] = JSON.parse(JSON.stringify(localStorage.getItem('apiLogs'))) || [];
    // if (!Array.isArray(existingLogs)){
    //   existingLogs = [];
    // }
    // appendLogs([requestLog]);
    // localStorage.setItem('apiLogs', JSON.stringify(existingLogs));

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (enableLogging){
      const responseLog = {
        timestamp: new Date().toISOString(),
        method: response.config.method,
        url: response.config.url,
        status: response.status,
        data: response.data,
      };
    // appendLogs([responseLog]);

    }
    //response
   

    // let existingLogs : any[] = JSON.parse(JSON.stringify(localStorage.getItem('apiLogs'))) || [];
    // if (!Array.isArray(existingLogs)) {
    //   existingLogs = [];
    // }
    // localStorage.setItem('apiLogs', JSON.stringify(existingLogs));

    return response;
  },
  (error: AxiosError) => {
    //error response
    if (enableLogging){
      const errorLog = {
        timestamp: new Date().toISOString(),
        method: error?.config?.method,
        url: error?.config?.url,
        status: error.response ? error.response.status : 'Network Error',
        error: error.message,
      };
    
    // appendLogs([errorLog]);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

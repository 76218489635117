import React from "react";
import { Box, Modal } from "@mui/material";

interface Props {
  open?: boolean;
}

const LoaderModal = ({ open }: Props) => {
  const style = {
    position: "absolute" as "absolute",

    width: "100%",
    height: "100%",
    bgcolor: "rgb(0,0,0,0.6)",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ":focus": {
      border: "none",
    },
  };

  return (
    <Modal
      open={open || false}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <img
          src="/assets/nbp_loader.gif"
          alt="loader"
          width={150}
          height={150}
        />
      </Box>
    </Modal>
  );
};

export default LoaderModal;

import React from "react";
import menuIcon from "../../stories/assets/icon-navigation-bar-menu.svg";
type MenuIconProps = {
  onClick?: () => void;
};

const MenuIcon: React.FC<MenuIconProps> = ({ onClick }) => {
  return (
    <div
      onClick={onClick && (() => onClick())}
      style={{ cursor: "pointer", margin: "20px 20px" }}
    >
      <img src={menuIcon} alt="" />
    </div>
  );
};

export default MenuIcon;

import { QueryCache, useMutation, useQuery } from "react-query";
import axios from "axios";
import queryClient from "./queryClient";
import axiosInstance from "./axiosInstance";
import { encryptedUrl } from "./EncrypteUrl";
import { consoleLog } from "../helper/consoleLogs";
import { decrypt } from "../helper/encrypt";

const PostLogin = async (user: { name: string; pass: string }) => {
  const headers = {
    "X-Auth-Username": user.name,
    Accept: "*/*",
    "X-Auth-Password": user.pass,
    IS_FP: "false",
    "X-Device-Token": "00",
    "X-Device-Type": "browser",
    "X-Device-ID": "00",
    "X-Device-Version": "00",
    "Make-Model": "",
    "Is-Rooted": "false",
    Channel: "INTERNET_BANKING",
  };
  return axiosInstance.post(encryptedUrl + "/api/v1/authenticate", null, {
    headers,
  });
};

export const PostLoginData = () => {
  return useMutation(PostLogin, {
    onSuccess: (data) => {
      // consoleLog("loginData", data);
      // consoleLog("post login data in api", data?.data?.data?.token);
      // queryClient.setQueryData("token", data?.data?.data?.token);
      // queryClient.setQueryData("loginData", data);
      if (data?.data?.responseCode === "00") {
        const decryptedData = decrypt(data?.data?.data);
        const updatedData = {
          ...data,
          data: {
            ...data.data,
            data: JSON.parse(decryptedData),
          },
        };
        consoleLog("loginData", updatedData);
        // console.log("loginData", decrypt(data?.data?.data));
        consoleLog("post login data in api", data?.data?.data?.token);
        queryClient.setQueryData("token", updatedData?.data?.data?.token);
        queryClient.setQueryData("loginData", updatedData);
      }
    },
  });
};
// No overload matches this call.
export const GetLoginData = (): any => {
  const loginData = queryClient.getQueryData("loginData");
  return loginData;
};

// export const GetTokenData = () => {
//   const tokenData = queryClient.getQueryData("token");
//   return tokenData;
// };

const PostLoginOtp = async (user: { otp: string; token: any }) => {
  const headers = { "X-Auth-Token": user.token };
  const payload = {
    deviceEnrolled: false,
    deviceName: "Browser",
    otp: user.otp,
  };

  return axiosInstance.post(
    encryptedUrl +
      "/api/v1/my/otpWithSmsAndEmail/device-verification?channel=INTERNET_BANKING",
    payload,
    { headers }
  );
};

export const PostLoginOtpData = () => {
  return useMutation(PostLoginOtp, {
    onSuccess: (data) => {
      consoleLog("post login otp in api", data);
      consoleLog("token in otp headers", data?.headers["x-auth-next-token"]);
      queryClient.setQueryData("token", data?.headers["x-auth-next-token"]);
    },
  });
};

// mpin after login and otp
const PostLoginMpin = async (user: {
  mPin: string;
  reEntermPin: string;
  token: any;
}) => {
  const headers = { "X-Auth-Token": user.token };
  const payload = {
    mPin: user.mPin,
    reEntermPin: user.reEntermPin,
  };
  return axiosInstance.post(
    encryptedUrl + "/api/v1/mpin/generate?channel=INTERNET_BANKING",
    payload,
    { headers }
  );
};

export const PostLoginMpinData = () => {
  return useMutation(PostLoginMpin, {
    onSuccess: (data) => {
      // consoleLog("post login mpin token in api", data?.data?.data?.token);
      queryClient.setQueryData("token", data?.data?.data?.token);
      queryClient.setQueryData("token", data?.headers["x-auth-next-token"]);
    },
  });
};

// START POST /api/v1/my/resendOtp/device-verification
const PostResendOtp = async (user: { token: any }) => {
  const headers = { "X-Auth-Token": user.token };
  return axiosInstance.post(
    encryptedUrl +
      "/api/v1/my/resendOtp/device-verification?channel=INTERNET_BANKING",
    null,
    { headers }
  );
};

export const PostResendOtpData = () => {
  return useMutation(PostResendOtp, {
    onSuccess: (data) => {
      consoleLog("post resend otp token in api", data?.data?.data?.token);
      queryClient.setQueryData("token", data?.headers["x-auth-next-token"]);
    },
  });
};

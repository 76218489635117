import { Capsule } from "../Capsule";
import { useEffect, useState } from "react";
import {
  // DropdownLink,
  MenuItem,
  MenuLink,
  // SidebarLabel
} from "./styles";
import { menuProps } from "./types";
import { Collapse } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const Menu = ({ menu, subnav, toggleSubnav }: menuProps) => {
  // const [subnav, setSubnav] = useState(false);
  const location = useLocation();

  // const showSubnav = () => setSubnav(!subnav);
  const showSubnav = () => {
    if (menu) {
      toggleSubnav(menu.title); // Pass the menu title to the Sidebar component to track which menu is open
    }
  };
  return (
    <>
      <div onClick={showSubnav}>
        {/* <Link to={menu.path || ""}> */}
        <Capsule
          path={menu?.path}
          title={menu.title}
          leftIcon={menu.leftIcon}
          // active={active}
          icon={menu.icon}
          subMenu={menu?.subMenu}
        />
        {/* </Link> */}
      </div>
      <Collapse in={subnav}>
        {menu.subMenu &&
          menu.subMenu.map((item, index) => {
            // consoleLog(
            //   "path is match in submenu",
            //   item.path === location.pathname
            // );
            // consoleLog("path is match", item?.path, location?.pathname);

            return (
              //   <DropdownLink to={item.path} key={index}>
              //   <SidebarLabel>{item.title}</SidebarLabel>
              // </DropdownLink>
              <MenuItem key={index}>
                {item.path ? (
                  <div className="menu-item">
                    <Link to={item.path} style={{ textDecoration: "none" }}>
                      <MenuLink
                        active={
                          item?.path === location?.pathname ||
                          location?.pathname.includes(item?.path)
                        }
                      >
                        {item.title}
                      </MenuLink>
                    </Link>
                  </div>
                ) : (
                  <MenuLink>{item.title}</MenuLink>
                )}
              </MenuItem>
            );
          })}
      </Collapse>
    </>
  );
};

export default Menu;

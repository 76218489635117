import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

interface LinksContainerProps {
  isMobile?: boolean;
}

interface LinkItemProps {
  isMobile?: boolean;
}

interface NavItemProps {
  isMobile?: boolean;
}

export const NavbarContainer = styled.nav<{
  variant?: "Primary" | "Secondary";
}>`
  background-color: ${({ variant }) =>
    variant === "Primary"
      ? "#e7e8e9"
      : variant === "Secondary"
      ? "#f8f8f8"
      : "#f8f8f8"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  height: 55px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

export const LogoContainer = styled.div<{
  variant?: "Primary" | "Secondary";
}>`
  display: flex;
  position: relative;
  align-items: center;
  width: ${({ variant }) =>
    variant === "Primary" ? "236px" : variant === "Secondary" ? "115px" : ""};
  padding: 0px 24px 0px 0px;
  height: 100%;
  background-color: ${({ variant }) =>
    variant === "Primary"
      ? "#0d1821"
      : variant === "Secondary"
      ? "#f8f8f8"
      : ""};
`;

export const Logo = styled.img`
  height: 30px;
  position: absolute;
  top: 20%;
  left: 20%;
`;

export const LinksContainer = styled.div<LinksContainerProps>`
  /* Common styles for link containers */
  display: flex;
  align-items: center;
  gap: ${({ isMobile }) => (isMobile ? "5px" : "15px")};
  padding-right: 20px;
`;

export const LinkItem = styled.div<LinkItemProps>`
  /* Common styles for link items */
  font-family: "FontRegular";
  font-weight: normal;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
    color: #439758;
  }
`;

export const NavDiv = styled.div<NavItemProps>`
  ${({ isMobile }) =>
    isMobile
      ? css`
          display: flex;
          // gap: 4em;
          align-items: center;
          width: 100%;
          justify-content: space-between;
        `
      : css`
          display: flex;
          margin-left: auto;
          margin-right: 0px;
          align-items: center;
        `}
`;

export const ActualLink = styled(Link)`
  color: #000;
  text-decoration: none;
  font-weight: normal;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
    color: #439758;
  }
`;

export const ExternalLink = styled.a`
  color: #000;
  text-decoration: none;
  font-weight: normal;
  font-size: 12px;

  &:hover {
    text-decoration: none;
    color: #000;
  }
  &:visited {
    text-decoration: none;
    color: #000;
  }
`;

import styled from "styled-components";

// export const ImageContainer = styled.div`
// width: 315px;
// height: 445px;
// `

const ImageContainer = styled.div`
  // width: 248px;
  height: 376px;
  border-radius:4px
`;

export const ImageSliderDiv = styled.div`
  margin-top: -200px;
  z-index: 4;
`;

export { ImageContainer };

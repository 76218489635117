import axios from "axios";
import { baseURL } from "./config";
import { useQuery } from "react-query";
import queryClient from "./queryClient";
import axiosInstance from "./axiosInstance";
import { encryptedUrl } from "./EncrypteUrl";
import { consoleLog } from "../helper/consoleLogs";

const GetDashBoard = async (accountNumber: string, token: any) => {
  const headers = { "X-Auth-Token": token };
  return axiosInstance.get(
    encryptedUrl +
      `/api/v1/my/accountOverview?accountNumber=${accountNumber}&channel=INTERNET_BANKING`,
    { headers }
  );
};

export const GetDashboardData = (accountNumber: string, token: any) => {
  return useQuery("dashboardData", () => GetDashBoard(accountNumber, token), {
    onSuccess: (data) => {
      consoleLog("dashboard data in api", data?.headers["x-auth-next-token"]);
      queryClient.setQueryData("token", data?.headers["x-auth-next-token"]);
      queryClient.setQueryData("dashboardData", data);
      // queryClient.setQueryData("loginData", data);
    },
    enabled: false,
    cacheTime: 0,
  });
};

export const GetDashboardAccountData = (): any => {
  const dashboardData = queryClient.getQueryData("dashboardData");
  return dashboardData;
};

const GetBalance = async (accountNumber: string, token: any) => {
  const headers = { "X-Auth-Token": token };
  return axiosInstance.get(
    encryptedUrl +
      `/api/v1/my/balanceenquiry?accountNumber=${accountNumber}&channel=INTERNET_BANKING`,
    { headers }
  );
};

export const GetBalanceData = (accountNumber: string, token: any) => {
  return useQuery("balance data", () => GetBalance(accountNumber, token), {
    onSuccess: (data) => {
      consoleLog("balance data in api", data?.headers["x-auth-next-token"]);
      queryClient.setQueryData("token", data?.headers["x-auth-next-token"]);
      // queryClient.setQueryData("balanceData", data);
    },
    enabled: false,
  });
};

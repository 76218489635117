import styled, { keyframes } from "styled-components";

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

export const StyledIconContainer = styled.div`
  display: inline-block;
  /* Add styles for the container if needed */
`;

export const StyledIcon = styled.div`
  width: 30px; /* Set the width of your icon */
  height: 30px; /* Set the height of your icon */
  transition: transform 0.3s ease; /* Add a smooth transition effect */
  animation: ${bounce} 1s infinite; /* Apply the bounce animation by default */

  img {
    width: 100%; /* Ensure the image fills the container */
    height: 100%; /* Ensure the image fills the container */
  }
`;

export const MainBody = styled.div`
  display: flex;
  flex-direction: row;
  // overflow: auto;
  //   justify-content: space-between;
`;

export const SidebarDiv = styled.div`
  width: 20%;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #0d1821;
`;
export const FixedSidebar = styled(SidebarDiv)`
  position: fixed;
  height: 100%;
  overflow-y: auto;
`;

export const DrawerSidebarDiv = styled.div`
  // margin-right: 20px;
  width: 100%;
`;

export const ChildrenDiv = styled.div`
  background-color: #eceded;
  // margin-left: 20px;
  // width: 896px !important;
`;

export const ImagesDiv = styled.div`
  background-color: #eceded;
  height: 56vh;
  padding-right: 15px;
  // margin-left: -40px;
`;
export const BodyDivWrapper = styled.div`
  background-color: #eceded;
  // display: flex;
  // justify-content: space-evenly;
  width: 100%;
  height: 100vh; /* Adjust the height to take the full viewport height */
  overflow: hidden; /* Hide overflow at the body level */
`;

export const BodyDiv = styled.div`
  overflow-y: auto; /* Enable vertical scrolling when needed */
  height: 100%; /* Occupy full height within its container */
`;

export const TitleDiv = styled.div`
  margin-left: 20px;
`;

export const MainPageDiv = styled.div`
  // height: 100vh;
  //  position: fixed;
`;

export const CardDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const RightBarDiv = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // gap: 15px;
  position: sticky;
  // top: 70px;
  padding-top: 55px;
`;

export const RaastMainDiv = styled.div`
  height: 27vh;
  border-radius: 4px;
  background-color: #fff;
  padding: 20px;
  margin-right: 5%;
`;
export const RaastInnerDiv = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  height: 90%;
  border-radius: 4px;
  background-color: #eceded;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

export const RaastOval = styled.div`
  width: 25px;
  height: 25px;
  padding: 18px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.09);
  background-image: linear-gradient(to right, #439758, #75fb93);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
`;
export const RaastNumber = styled.div`
  font-size: 16px;
`;
export const CopyDiv = styled.div`
  background-color: #0d1821;
  color: #fff;
  padding: 5px 20px;
  border-radius: 15px;
  cursor: pointer;
`;

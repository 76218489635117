import { Box, Button, Modal } from "@mui/material";
import React from "react";

interface Props {
  open?: boolean;
  onClose?: () => void;
  setSuccessSubmit?: () => void;
  children?: any;
}

const RaastModal = ({ open, onClose, setSuccessSubmit, children }: Props) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "388px",
    bgcolor: "background.paper",
    borderRadius: "5px",
    border: "none",
    textAlign: "center",
    ":focus": {
      border: "none",
    },
  };

  return (
    <Modal
      open={open || false}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pt: 0.5,
            pr: 0.5,
            fontFamily: "FontRegular",
          }}
        >
          <img
            src="/assets/icons-filled-24-cross.svg"
            alt="Close"
            onClick={onClose}
            style={{ cursor: "pointer", marginRight: "0px" }}
          />
        </Box>
        <Box sx={{ px: 3, pb: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 0.5,
              pr: 0.5,
              fontFamily: "FontRegular",
            }}
          >
            <img
              src="/assets/rast.jpg"
              alt="raast icon"
              height={150}
              width={150}
            />
          </Box>
          <Box sx={{ fontSize: "24px", fontFamily: "FontDemiBold" }}>
            Welcome to Raast
          </Box>
          <Box
            sx={{
              paddingX: "20px",
              fontFamily: "FontRegular",
              pt: 2,
              fontSize: "20px",
              color: "darkgray",
            }}
          >
            {children}
          </Box>
          <Button
            sx={{
              width: "100%",
              backgroundColor: "#009951",
              color: "#fff",
              height: "52px",
              marginTop: "30px",
              textTransform: "capitalize",
              ":hover": {
                backgroundColor: "#0eb466",
              },
              fontFamily: "FontDemiBold",
            }}
            onClick={setSuccessSubmit}
          >
            Register Raast ID now
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RaastModal;

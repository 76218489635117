import { useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Router from "./routes";
import { WrapperComponent } from "./stories/WrapperComponent";
import { GetLoginData } from "./Api/Login";
import { useEffect, useState } from "react";
import { consoleLog } from "./helper/consoleLogs";
import AlertModal from "./components/AlertModal";

function App() {
  const [showRaastID, setShowRaastID] = useState(false);
  const location = useLocation();
  const [isModal, setIsModal] = useState(false);
  const data = GetLoginData();
  const history = useNavigate();

  useEffect(() => {
    if (
      !(
        location.pathname.includes("my-account") ||
        location.pathname.includes("settings") ||
        location.pathname.includes("login") ||
        location.pathname.includes("debit-card") ||
        location.pathname.includes("otp") ||
        location.pathname === "/change-pass-l0" ||
        location.pathname === "/change-pass-l2" ||
        location.pathname.includes("nbp-upgrade-registration") ||
        location.pathname === "/login-mpin" ||
        location.pathname === "/updated-terms" ||
        location.pathname === "/raast/manage-raast" ||
        location.pathname === "/raast/register-raast" ||
        location.pathname === "/raast/confirm-register" ||
        location.pathname === "/raast/link-raast" ||
        location.pathname === "/raast/confirm-link" ||
        location.pathname === "/raast/delink-raast" ||
        location.pathname === "/raast/confirm-delink" ||
        location.pathname === "/app" ||
        location.pathname === "/certificates" 
      )
    ) {
      if (
        data?.data?.data?.details?.customerlevel === "L1" ||
        data?.data?.data?.details?.customerlevel === "L0"
      ) {
        setIsModal(true);
      }
    }
    if (location.pathname === "/my-account/dormant-account") {
      if (data?.data?.data?.details?.customerlevel === "L0") {
        setIsModal(true);
      }
    }
  }, [location]);

  consoleLog("location path name", location.pathname);

  const loginData = GetLoginData();
  consoleLog("raast pop up", loginData?.data?.data?.details?.raastPopup);

  useEffect(() => {
    setShowRaastID(loginData?.data?.data?.details?.raastPopup);
  }, [loginData]);

  const onClose = () => {
    if (location.pathname === "/app") {
      setIsModal(false);
    } else {
      setIsModal(false);
      history("/app");
    }
  };

  const onClick = () => {
    setIsModal(false);
    history("/nbp-upgrade-registration/debit-pin", { state: {} });
  };

  return (
    <>
      {location.pathname.includes("login") ||
      location.pathname.includes("NBP-Products") ||
      location.pathname === "/terms-and-conditions" ||
      location.pathname === "/change-pass-l0" ||
      location.pathname === "/change-pass-l2" ||
      location.pathname === "/register-account" ||
      location.pathname === "/register-otp" ||
      location.pathname === "/register-mpin" ||
      location.pathname === "/cnic-forget-password" ||
      location.pathname === "/forget-password-l0" ||
      location.pathname === "/forget-password-l2" ||
      location.pathname === "/debit-card/card-otp" ||
      location.pathname === "/debit-card/change-pin-otp" ||
      location.pathname === "/term-&-condition" ||
      
      location.pathname === "/contact" ||
      location.pathname === "/faqs-login" ||
      location.pathname === "/updated-terms" ||
      location.pathname.includes("nbp-upgrade-registration") ? (
        location.pathname === "/nbp-upgrade-registration/debit-pin" ||
        location.pathname ===
          "/nbp-upgrade-registration/upgrade-registration" ? (
          // <RequireAuth>
          <Router />
        ) : (
          // </RequireAuth>
          <Router />
        )
      ) : location.pathname === "/app" ? (
        // <RequireAuth>
        <WrapperComponent
          showRaastID={showRaastID}
          raastIdPresent={!showRaastID}
        >
          <Router />
        </WrapperComponent>
      ) : (
        // </RequireAuth>
        // <RequireAuth>
        <WrapperComponent>
          <Router />
        </WrapperComponent>
        //  </RequireAuth>
      )}
      <AlertModal
        responseCode="00"
        open={isModal}
        setSuccessSubmit={onClose}
      >
        Please use NBP Mobile Application to upgrade your registration
      </AlertModal>
      {/* <ModalPopUp
        Title="Upgrade Registration"
        isOpen={isModal}
        onClose={onClose}
        variant="Default"
        id="Header"
        children={
          <>
            <div
              style={{
                width: "340px",
                textAlign: "center",
                marginLeft: "3em",
              }}
            >
              <Title
                TitleText="You need to upgrade your registration to unlock all features. Do you want to upgrade?"
                role="h3"
              />
            </div>
            <div style={{ margin: "3em 0em 0em 3em" }}>
              <Button label="No" variant="gray" fontBold onClick={onClose} />
            </div>
            <div style={{ margin: "1em 0em 1em 3em" }}>
              <Button
                label="Yes"
                variant="default"
                fontBold
                onClick={onClick}
                buttonType="submit"
              />
            </div>
          </>
        }
      />  */}
    </>
  );
}

export default App;

import { Link } from "react-router-dom";
import styled from "styled-components";

export const CapsuleContainer = styled(Link)<{
  variant?: "Black" | "Grey";
  active?: boolean;
}>`
  display: flex;
  align-items: center;
  background-color: ${({ active }) => (active ? "rgba(0, 255, 0, 0.3)" : "")};
  border-radius: 2px;
  text-decoration: none;
  &:hover {
    background-color: ${({ variant }) =>
      variant === "Grey" ? "#e9eaeb" : "rgba(0, 255, 0, 0.3)"};
  }
  &:active {
    background-color: ${({ variant }) =>
      variant === "Grey" ? "#eceded" : "#439758"};
  }
  padding: 0 10px;
  gap: 15px;
`;
export const CapsuleDiv = styled.div<{
  variant?: "Black" | "Grey";
  active?: boolean;
}>`
  display: flex;
  align-items: center;
  background-color: ${({ active }) => (active ? "rgba(0, 255, 0, 0.3)" : "")};
  border-radius: 2px;
  text-decoration: none;
  &:hover {
    background-color: ${({ variant }) =>
      variant === "Grey" ? "#e9eaeb" : "rgba(0, 255, 0, 0.3)"};
  }
  &:active {
    background-color: ${({ variant }) =>
      variant === "Grey" ? "#eceded" : "#439758"};
  }
  padding: 0 10px;
  gap: 15px;
`;

export const CapsuleTitle = styled.h3<{
  variant?: "Black" | "Grey";
}>`
  font-family: FontRegular;
  font-size: 6px;
  font-weight: normal;
  color: ${({ variant }) => (variant === "Grey" ? "#000" : "#fff")};
`;

export const CapsuleIcon = styled.span<{
  leftIcon?: boolean;
  rightIcon?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ leftIcon, rightIcon }) =>
    leftIcon || rightIcon ? "row" : "row-reverse"};

  svg {
    ${({ leftIcon, rightIcon }) =>
      leftIcon || rightIcon ? `margin-right: 15px;` : `margin-left: 15px;`}
    ${({ leftIcon, rightIcon }) =>
      leftIcon || rightIcon ? `color:#fff` : `color:#fff`}
  }
`;

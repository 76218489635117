import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { FiAlertCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import queryClient from "../../Api/queryClient";

interface Props {
  open?: boolean;

  setSuccessSubmit?: any;
  children?: any;
  buttonDesc?: string;
  responseCode: string;
}

const AlertModal = ({
  open,

  setSuccessSubmit,
  buttonDesc,
  children,
  responseCode,
}: Props) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "25%",
    bgcolor: "background.paper",
    borderRadius: "5px",
    border: "none",
    textAlign: "center",
    ":focus": {
      border: "none",
    },
    zIndex: 1000,
  };
  if (window.innerWidth <= 768) {
    style.width = "80%";
  }

  const ReactQueryClient = useQueryClient();

  const navigate = useNavigate();
  const handleNavigate = () => {
    if (
      responseCode === "605" ||
      responseCode === "606" ||
      responseCode === "17" ||
      // responseCode === "99" ||
      responseCode === "71"
    ) {
      ReactQueryClient.clear();
      queryClient.clear();
      navigate("/login");
    } else {
      setSuccessSubmit();
    }
  };

  return (
    <Modal
      open={open || false}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pt: 0.5,
            pr: 0.5,
            fontFamily: "FontRegular",
          }}
        >
          <img
            src="/assets/icons-filled-24-cross.svg"
            alt="Close"
            onClick={handleNavigate}
            style={{ cursor: "pointer", marginRight: "0px" }}
          />
        </Box>
        <Box sx={{ px: 3, pb: 2 }}>
          <Box>
            {/* <img src="/assets/filled-check.svg" /> */}
            <FiAlertCircle size={50} color="#fac65f" />
          </Box>
          <Typography
            variant="h5"
            sx={{
              fontFamily: "FontDemiBold",
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            Alert!
          </Typography>
          <Box sx={{ paddingX: "10px", color: "#565d64" }}>
            {responseCode === "605"
              ? "Dear Customer, You are Inactive for too long or your session might be expired, please Login again.  For Further Assistance, Please Call NBP HelpLine 021-111-627-627."
              : children}
          </Box>
          <Button
            sx={{
              width: "100%",
              backgroundColor: "#fac65f",
              color: "#fff",
              height: "52px",
              marginTop: "30px",
              textTransform: "capitalize",
              ":hover": {
                backgroundColor: "#ff8800",
              },
              fontFamily: "FontDemiBold",
            }}
            // onClick={setSuccessSubmit}
            onClick={handleNavigate}
          >
            {buttonDesc || "Ok"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AlertModal;

import styled from "styled-components";

interface HeadingProps {
  role?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "p"
    | "p1"
    | "p2"
    | "p3"
    | "p4"
    | "p5"
    | "p6"
    | "h";
  isTitleMargin?: boolean;
  id?:'Urdu';
  Textcolor?: string;
  TitleMargin?: string;
}

export const TsHeading = styled.p<HeadingProps>`
  // font-family: ArticulatCF;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: ${({ isTitleMargin, TitleMargin }) => TitleMargin && TitleMargin && isTitleMargin && "12px 0"};
  color: ${({ Textcolor }) => (Textcolor ? Textcolor : null)};
  float: ${({ id }) => id === 'Urdu'? 'right': 'none'};
  font-weight: ${({ role }) =>
    role === "h1"
      ? "bold"
      : role === "p1"
      ? "normal"
      : role === "h2"
      ? "bold"
      : role === "p2"
      ? "normal"
      : role === "h3"
      ? "bold"
      : role === "p3"
      ? "normal"
      : role === "h4"
      ? "bold"
      : role === "p4"
      ? "normal"
      : role === "h5"
      ? "bold"
      : role === "p5"
      ? "600"
      : role === "h6"
      ? "bolder"
      : role === "p"
      ? "normal"
      : role === "h"
      ? "normal"
      : role === "p6"
      ? "normal"
      : "0px"};
  font-size: ${({ role }) =>
    role === "h1"
      ? "80px"
      : role === "h2"
      ? "28px"
      : role === "h3"
      ? "20px"
      : role === "h4"
      ? "24px"
      : role === "h5"
      ? "18px"
      : role === "h6"
      ? "14px"
      : role === "p"
      ? "19px"
      : role === "h"
      ? "14px"
      : role === "p1"
      ? "17px"
      : role === "p2"
      ? "16px"
      : role === "p3"
      ? "14px"
      : role === "p4"
      ? "12px"
      : role === "p5"
      ? "17px"
      : role === "p6"
      ? "10px"
      : "0px"};
`;

export const PrimaryTilte = styled.h5`
  // font-family: ArticulatCF;
  display: inline;
`;

export const TilteHead = styled.a`
  display: inline;
  // font-family: ArticulatCF;
  margin-left: 5px;
`;

export const RegTitleOne = styled.p`
  // font-family: ArticulatCF;
  display: inline;
  font-size : 16px;
`;

export const RegTitleTwo = styled.p`
  display: inline;
  margin-left: 5px;
  font-size : 16px;
`;
export const InlineDiv = styled.div``;

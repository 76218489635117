import React from "react";
import { Heading } from "../Modal/styles";
import { TsHeading, TilteHead, PrimaryTilte,RegTitleOne,RegTitleTwo, InlineDiv } from "./styles";
import { TitleProps } from "./types";

export const Title = ({
  role,
  TitleText,
  TitleTextP,
  isTitleMargin,
  TitleMargin,
  Textcolor,
  variant,
  id,
  ...props
}: TitleProps) => {
  return (
    <>
      {variant === "Double" ? (
        <TsHeading role={role} id={id}>
          <InlineDiv>
            {" "}
            <RegTitleOne>{TitleTextP}</RegTitleOne>{" "}
            <RegTitleTwo>{TitleText}</RegTitleTwo>
          </InlineDiv>
        </TsHeading>
      ) :
      
      role !== "h" ? (
        <TsHeading TitleMargin={TitleMargin} isTitleMargin={isTitleMargin} role={role} id={id} Textcolor={Textcolor}>
          {TitleText}
        </TsHeading>
      ) :
      
      (
        <TsHeading role={role} id={id}>
          <InlineDiv>
            {" "}
            <PrimaryTilte>{TitleTextP}</PrimaryTilte>{" "}
            <TilteHead>{TitleText}</TilteHead>
          </InlineDiv>
        </TsHeading>
      )}
    </>
  );
};

export default Title;

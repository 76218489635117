import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";

import DashboardLayout from "../pages/Main/DashboardLayout";
// import { PATH_DASHBOARD } from "./paths";
import { BallTriangle } from "react-loader-spinner";

const Loadable = (Component) => (props) => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/assets/nbp_loader.gif"
            alt="loader"
            width={150}
            height={150}
          />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        // {
        //   element: <Navigate to="login" replace />,
        //   index: true,
        // },
        {
          path: "app",
          element: <Dashboard />,
        },
        // {
        //   path: "term-and-condition",
        //   element: <TermAndCondition />,
        // },
        {
          path: "contact-us",
          element: <ContactUs />,
        },
        {
          path: "contact",
          element: <ContactLogin />,
        },
        // {
        //   path: "debit-card-management",
        //   element: <DebitCardManagement />,
        // },
        { path: "login", element: <SignIn /> },
        { path: "NBP-Products", element: <NBPProducts /> },
        { path: "login-otp", element: <LoginOTP /> },
        { path: "login-mpin", element: <LoginMpin /> },
        { path: "forget-password", element: <PasswordPage /> },
        { path: "cnic-forget-password", element: <CnicForgotPass /> },
        { path: "forget-password-l0", element: <ForgotPasswordL0 /> },
        { path: "forget-password-l2", element: <ForgotPasswordL2 /> },
        { path: "terms-and-conditions", element: <TermsPage /> },
        { path: "register-account", element: <RegisterAccount /> },
        { path: "register-otp", element: <RegisterOtp /> },
        { path: "register-mpin", element: <RegisterMpin /> },
        { path: "change-pass-l0", element: <ChangePassL0 /> },
        { path: "change-pass-l2", element: <ChangePassL2 /> },
        { path: "invest-stocks", element: <InvestStocks /> },
        { path: "invest-gold", element: <InvestGold /> },
        { path: "invest-mutual-fund", element: <InvestMutualFunds /> },
        {
          path: "updated-terms",
          element: <UpgradeTerms />,
        },
        // { path: "raast-qr", element: <RaastQR /> },
        {
          path: "raast-qr",
          // element: <MyAccount />,
          children: [
            {
              path: "static-qr",
              element: <RaastQR />,
            },
            {
              path: "dynamic-qr",
              element: <DynamicQR />,
            },
          ],
        },

        {
          path: "nbp-upgrade-registration",
          children: [
            {
              element: <Navigate to={"nbp-upgrade-registration"} replace />,
              index: true,
            },

            {
              path: "debit-pin",
              element: <DebitPin />,
            },
            {
              path: "upgrade-registration",
              element: <UpgradeRegistration />,
            },
          ],
        },
        {
          path: "payments",
          // element: <Beneficiary />,
          children: [
            {
              path: "electricty-bill-confirm",
              element: <ElectrictyBillConfirm />,
            },
            {
              path: "electricty-bill-benef",
              element: <ElectrictyBillBenef />,
            },
            {
              path: "gas-bill-benef",
              element: <GasBillBenef />,
            },
            {
              path: "ptcl-bill-benef",
              element: <PtclBillBenef />,
            },
            {
              path: "utility-gas-bill-payments",
              element: <UtilityGasBillPayment />,
            },
            {
              path: "utility-gas-bill-details",
              element: <UtilityGasBillDetails />,
            },
            {
              path: "utility-gas-bill-confirm",
              element: <UtilityGasBillConfirm />,
            },
            {
              path: "utility-gas-bill-mpin",
              element: <UtilityGasBillMpin />,
            },
            {
              path: "utility-ptcl-bill-payments",
              element: <UtilityPTCLBillPayment />,
            },
            {
              path: "utility-ptcl-bill-details",
              element: <UtilityPTCLBillDetails />,
            },
            {
              path: "utility-ptcl-bill-confirm",
              element: <UtilityPTCLBillConfirm />,
            },
            {
              path: "utility-water-bill-payments",
              element: <UtilityWaterBillPayment />,
            },
            {
              path: "water-bill-benef",
              element: <WaterBillBenef />,
            },
            {
              path: "utility-water-bill-details",
              element: <UtilityWaterBillDetails />,
            },
            {
              path: "utility-water-bill-confirm",
              element: <UtilityWaterBillConfirm />,
            },
            {
              path: "utility-water-bill-mpin",
              element: <UtilityWaterBillMpin />,
            },
            {
              path: "utility-ptcl-bill-mpin",
              element: <UtilityPtclBillMpin />,
            },
            {
              path: "mobile-bill-payments",
              element: <MobileBillPayments />,
            },
            {
              path: "zong-bill-payments",
              element: <ZongBillPayment />,
            },
            {
              path: "zong-bill-details",
              element: <ZongBillDetails />,
            },
            {
              path: "zong-bill-confirm",
              element: <ZongBillConfirm />,
            },
            {
              path: "zong-bill-mpin",
              element: <ZongBillMpin />,
            },
            {
              path: "zong-bill-benef",
              element: <ZongBillBenef />,
            },
            {
              path: "ufone-bill-payments",
              element: <UfoneBillPayment />,
            },
            {
              path: "ufone-bill-details",
              element: <UfoneBillDetails />,
            },
            {
              path: "ufone-bill-confirm",
              element: <UfoneBillConfirm />,
            },
            {
              path: "ufone-bill-mpin",
              element: <UfoneBillMpin />,
            },
            {
              path: "ufone-bill-benef",
              element: <UfoneBillBenef />,
            },
            {
              path: "jazz-bill-payments",
              element: <JazzBillPayment />,
            },
            {
              path: "jazz-bill-details",
              element: <JazzBillDetails />,
            },
            {
              path: "jazz-bill-confirm",
              element: <JazzBillConfirm />,
            },
            {
              path: "jazz-bill-mpin",
              element: <JazzBillMpin />,
            },
            { path: "jazz-bill-benef", element: <JazzBillBenef /> },
            {
              path: "telenor-bill-payments",
              element: <TelenorBillPayment />,
            },
            {
              path: "telenor-bill-details",
              element: <TelenorBillDetails />,
            },
            {
              path: "telenor-bill-confirm",
              element: <TelenorBillConfirm />,
            },
            { path: "telenor-bill-benef", element: <TelenorBillBenef /> },
            {
              path: "telenor-bill-mpin",
              element: <TelenorBillMpin />,
            },
            {
              path: "electricity-payments-mpin",
              element: <PaymentsMPIN />,
            },

            {
              path: "internet-payments",
              element: <InternetBillPayments />,
            },
            {
              path: "internet-bill-confirm",
              element: <InternetBillConfirm />,
            },
            {
              path: "internet-bill-details",
              element: <InternetBillDetails />,
            },
            {
              path: "internet-bill-mpin",
              element: <InternetBillPaymentMpin />,
            },
            { path: "internet-bill-benef", element: <InternetBillBenef /> },

            {
              path: "online-payments",
              element: <OnlineBillPayments />,
            },
            {
              path: "online-bill-details",
              element: <OnlineBillDetails />,
            },
            {
              path: "online-bill-confirm",
              element: <OnlineBillConfirm />,
            },
            {
              path: "online-bill-mpin",
              element: <OnlineBillPaymentMpin />,
            },

            {
              path: "education-payments",
              element: <EducationBillPayments />,
            },
            {
              path: "education-bill-benef",
              element: <EducationBillPaymentsBenef />,
            },
            {
              path: "education-bill-confirm",
              element: <EducationBillPaymentsConfirm />,
            },
            {
              path: "education-bill-mpin",
              element: <EducationBillPaymentMpin />,
            },
            {
              path: "asset-payments",
              element: <AssetBillPayments />,
            },
            {
              path: "asset-bill-confirm",
              element: <AssetBillPaymentsConfirm />,
            },
            {
              path: "asset-bill-mpin",
              element: <AssetBillPaymentMpin />,
            },
            {
              path: "financial-payments",
              element: <FinancialBillPayments />,
            },
            {
              path: "financial-bill-confirm",
              element: <FinancialPaymentsConfirm />,
            },
            {
              path: "financial-bill-mpin",
              element: <FinancialBillPaymentMpin />,
            },

            {
              path: "insurance-payments",
              element: <InsuranceBillPayments />,
            },
            {
              path: "insurance-bill-confirm",
              element: <InsuranceBillPaymentsConfirm />,
            },
            {
              path: "insurance-bill-mpin",
              element: <InsuranceBillPaymentMpin />,
            },
            {
              path: "insurance-bill-benef",
              element: <InsuranceBillBenef />,
            },
            {
              path: "investment-payments",
              element: <InvestmentBillPayments />,
            },
            {
              path: "investment-bill-confirm",
              element: <InvestmentBillPaymentsConfirm />,
            },
            {
              path: "investment-bill-mpin",
              element: <InvestmentBillPaymentMpin />,
            },
            {
              path: "investment-bill-benef",
              element: <InvestmentBillBenef />,
            },
            {
              path: "government-payments",
              element: <GovernmentBillPayments />,
            },
            {
              path: "government-bill-confirm",
              element: <GovernmentBillPaymentsConfirm />,
            },
            {
              path: "government-bill-benef",
              element: <GovernmentBillPaymentsBenef />,
            },
            {
              path: "government-bill-mpin",
              element: <GovernmentBillPaymentMpin />,
            },
            {
              path: "psid-generation",
              element: <PsidGeneration />,
            },
            {
              path: "other-payments",
              element: <OtherBillPayments />,
            },
            {
              path: "other-bill-confirm",
              element: <OtherBillPaymentsConfirm />,
            },
            {
              path: "other-bill-mpin",
              element: <OtherBillPaymentMpin />,
            },
            {
              path: "other-bill-benef",
              element: <OtherBillBenef />,
            },
            {
              path: "credit-payments",
              element: <CreditBillPayments />,
            },
            {
              path: "credit-bill-confirm",
              element: <CreditBillPaymentsConfirm />,
            },
            {
              path: "credit-bill-mpin",
              element: <CreditBillPaymentMpin />,
            },
            {
              path: "credit-bill-Benef",
              element: <CreditBillPaymentBenef />,
            },
            {
              path: "topup-payments",
              element: <TopupBillPayments />,
            },
            {
              path: "topup-bill-confirm",
              element: <TopupBillPaymentsConfirm />,
            },
            {
              path: "topup-bill-benef",
              element: <TopupBillPaymentsBenef />,
            },
            {
              path: "topup-bill-mpin",
              element: <TopupBillPaymentMpin />,
            },
            {
              path: "voucher-payments",
              element: <VoucherBillPayments />,
            },
            {
              path: "voucher-bill-confirm",
              element: <VoucherBillPaymentsConfirm />,
            },
            {
              path: "voucher-bill-benef",
              element: <VoucherBillPaymentsBenef />,
            },
            {
              path: "voucher-bill-mpin",
              element: <VoucherBillPaymentMpin />,
            },
          ],
        },
        {
          path: "/",
          element: <DashboardLayout />,
          children: [
            {
              element: <Navigate to="login" replace />,
              index: true,
            },
            {
              path: "app",
              element: <Dashboard />,
            },
            {
              path: "term-and-condition",
              element: <TermAndCondition />,
            },
            {
              path: "faqs",
              element: <Faqs />,
            },
            {
              path: "faqs-login",
              element: <Faqs />,
            },
            {
              path: "term-&-condition",
              element: <TandC />,
            },
            {
              path: "contact-us",
              element: <ContactUs />,
            },
            {
              path: "debit-card-management",
              element: <DebitCardManagement />,
            },
            {
              path: "certificates",
              element: <Certificates />,
            },
            {
              path: "certificates/account-maintenance",
              element: <AccountMaintenance />,
            },
            {
              path: "certificates/wht",
              element: <WithHoldingTax />,
            },
            {
              path: "certificates/balance",
              element: <BalanceCertificate />,
            },

            // { path: 'login', element: <SignIn /> },
            // { path: 'forget-password', element: <PasswordPage /> },
            // { path: 'terms-and-conditions', element: <TermsPage /> },

            {
              path: "payments",
              // element: <Beneficiary />,
              children: [
                // {
                //   element: <Navigate to={"utility-bill-payments"} replace />,
                //   index: true,
                // },
                {
                  path: "utility-bill-payments",
                  element: <UtilityBillPayments />,
                },
                {
                  path: "electricty-bill-payments",
                  element: <ElectrictyBillPayment />,
                },
                {
                  path: "electricty-bill-details",
                  element: <ElectrictyBillDetails />,
                },
                {
                  path: "electricty-bill-confirm",
                  element: <ElectrictyBillConfirm />,
                },
              ],
            },

            {
              path: "transfer",
              // element: <TransfersPages />,
              children: [
                {
                  element: <Navigate to={"fund-transfer"} replace />,
                  index: true,
                },
                {
                  path: "fund-transfer",
                  element: <FundTransfer />,
                },
                {
                  path: "fund-transfer-benef",
                  element: <FundTransferBenef />,
                },
                {
                  path: "Inter-Bank-fund-transfer",
                  element: <InterBankFundTransfer />,
                },
                {
                  path: "Ibft-transfer-benef",
                  element: <IbftTransferBenef />,
                },
                {
                  path: "cnic-transfer",
                  element: <CnicTransfer />,
                },
                {
                  path: "cnic-mpin",
                  element: <TransferMPIN />,
                },
                {
                  path: "transfer-funds",
                  element: <TransferFunds />,
                },
                {
                  path: "transfer-funds-mpin",
                  element: <FundTransferMpin />,
                },
                {
                  path: "ibft-mpin",
                  element: <IbftTransferMpin />,
                },
                {
                  path: "transfer-confirm-detail",
                  element: <ConfirmDetail />,
                },
                {
                  path: "ibft-confirm-detail",
                  element: <ConfirmIbftDetail />,
                },
                {
                  path: "cnic-confirm-detail",
                  element: <ConfirmCnicDetail />,
                },
                {
                  path: "raast-transfer",
                  element: <RaastTransfer />,
                },
                {
                  path: "raast-transfer-confirm",
                  element: <RaastTransferConfirm />,
                },
                {
                  path: "raast-transfer-mpin",
                  element: <RaastTransferMpin />,
                },
                {
                  path: "raast-transfer-benef",
                  element: <RaastTransferBenef />,
                },
              ],
            },
            {
              path: "raast",
              // element: <Raast />,
              children: [
                {
                  element: <Navigate to={"manage-raast"} replace />,
                  index: true,
                },
                {
                  path: "manage-raast",
                  element: <RaastManagement />,
                },
                {
                  path: "register-raast",
                  element: <RegisterRaast />,
                },
                {
                  path: "confirm-register",
                  element: <ConfirmRegister />,
                },
                {
                  path: "link-raast",
                  element: <LinkRaast />,
                },
                {
                  path: "confirm-link",
                  element: <ConfirmLink />,
                },
                {
                  path: "delink-raast",
                  element: <DelinkRaast />,
                },
                {
                  path: "confirm-delink",
                  element: <ConfirmDelink />,
                },
                {
                  path: "remove-raast",
                  element: <RemoveRaast />,
                },
                {
                  path: "confirm-remove",
                  element: <ConfirmRemove />,
                },
              ],
            },
            {
              path: "app",
              element: <Dashboard />,
            },
            {
              path: "complaints",
              element: <Complaints />,
            },
            {
              path: "donations-list",
              element: <DonationList />,
            },
            {
              path: "donation-payment",
              element: <DonationPayment />,
            },
            {
              path: "sports-club-fee",
              element: <SportsClub />,
            },

            // {
            //   path: "term-and-condition",
            //   element: <TermAndCondition />,
            // },
            {
              path: "transfer",
              // element: <TransfersPages />,
              children: [
                {
                  element: <Navigate to={"fund-transfer"} replace />,
                  index: true,
                },
                {
                  path: "fund-transfer",
                  element: <FundTransfer />,
                },
                {
                  path: "Inter-Bank-fund-transfer",
                  element: <InterBankFundTransfer />,
                },
                {
                  path: "cnic-transfer",
                  element: <CnicTransfer />,
                },
                {
                  path: "mpin",
                  element: <TransferMPIN />,
                },
                {
                  path: "transfer-funds",
                  element: <TransferFunds />,
                },
                {
                  path: "transfer-confirm-detail",
                  element: <ConfirmDetail />,
                },
              ],
            },
            {
              path: "contact-us",
              element: <ContactUs />,
            },
            { path: "debit-card-management", element: <DebitCardManagement /> },
            {
              path: "settings",
              // element: <Settings />,
              children: [
                // {
                //   element: <Navigate to={"change-password"} replace />,
                //   index: true,
                // },
                {
                  path: "change-password",
                  element: <ChangePassword />,
                },
                {
                  path: "change-mpin",
                  element: <ChangeMpin />,
                },
                {
                  path: "forgot-mpin",
                  element: <ForgotMpin />,
                },
                {
                  path: "forgot-mpin-verify",
                  element: <ForgotMpinVerify />,
                },
              ],
            },
            {
              path: "my-account",
              // element: <MyAccount />,
              children: [
                {
                  element: <Navigate to={"add-account"} replace />,
                  index: true,
                },
                {
                  path: "add-account",
                  element: <AddAccount />,
                },
                {
                  path: "add-account-mpin",
                  element: <AddAccountMpin />,
                },
                {
                  path: "manage-account",
                  element: <ManageAccount />,
                },
                {
                  path: "view-account",
                  element: <ViewAccout />,
                },
                {
                  path: "dormant-account",
                  element: <DormantAccount />,
                },
                {
                  path: "dormant-account-mpin",
                  element: <DormantAccountMpin />,
                },
                {
                  path: "manage-limit",
                  element: <ManageLimit />,
                },
                {
                  path: "view-limit",
                  element: <ViewLimit />,
                },
                {
                  path: "limit-management",
                  element: <LimitManagement />,
                },
                {
                  path: "limit-management-mpin",
                  element: <LimitManagementMpin />,
                },
                {
                  path: "profits",
                  element: <MyProfit />,
                },
              ],
            },
            {
              path: "transfer",
              // element: <TransfersPages />,
              children: [
                {
                  element: <Navigate to={"fund-transfer"} replace />,
                  index: true,
                },
                {
                  path: "fund-transfer",
                  element: <FundTransfer />,
                },
                {
                  path: "Inter-Bank-fund-transfer",
                  element: <InterBankFundTransfer />,
                },
                {
                  path: "cnic-transfer",
                  element: <CnicTransfer />,
                },
                {
                  path: "mpin",
                  element: <TransferMPIN />,
                },
                {
                  path: "transfer-confirm-detail",
                  element: <ConfirmDetail />,
                },
              ],
            },
            {
              path: "beneficiary",
              // element: <Beneficiary />,
              children: [
                {
                  element: <Navigate to={"add-beneficiary"} replace />,
                  index: true,
                },
                {
                  path: "add-beneficiary",
                  element: <AddBeneficiary />,
                },

                {
                  path: "edit-beneficiary",
                  element: <EditBeneficiary />,
                },
                {
                  path: "add-raast",
                  element: <AddRaastBeneficiary />,
                },
                {
                  path: "add-raast-confirm",
                  element: <AddRaastBenefConfirm />,
                },
                {
                  path: "add-raast-mpin",
                  element: <AddRaastBenefMpin />,
                },
                {
                  path: "intra-bank-transfer",
                  element: <IntraBankTransfer />,
                },
                {
                  path: "intra-bank-confirm",
                  element: <FTConfirmDetails />,
                },
                {
                  path: "intra-bank-mpin",
                  element: <IntraBankMpin />,
                },
                {
                  path: "inter-bank-transfer",
                  element: <InterBankTransfer />,
                },
                {
                  path: "inter-bank-confirm",
                  element: <InterBankConfirm />,
                },
                {
                  path: "inter-bank-mpin",
                  element: <InterBankMpin />,
                },
                {
                  path: "electricity-bill-payment",
                  element: <ElectricityBillPayment />,
                },
                {
                  path: "electricity-bill-confirm",
                  element: <ElectricityBillConfirm />,
                },
                {
                  path: "electricity-bill-mpin",
                  element: <ElectricityBillMpin />,
                },

                {
                  path: "gas-bill-payment",
                  element: <GasBillPayment />,
                },
                {
                  path: "gas-bill-confirm",
                  element: <GasBillConfirm />,
                },
                {
                  path: "gas-bill-mpin",
                  element: <GasBillMpin />,
                },
                {
                  path: "water-bill-payment",
                  element: <WaterBillPayment />,
                },
                {
                  path: "water-bill-confirm",
                  element: <WaterBillConfirm />,
                },
                {
                  path: "water-bill-mpin",
                  element: <WaterBillMpin />,
                },
                {
                  path: "ptcl-bill-payment",
                  element: <PtclBillPayment />,
                },
                {
                  path: "ptcl-bill-confirm",
                  element: <PtclBillConfirm />,
                },
                {
                  path: "ptcl-bill-mpin",
                  element: <PtclBillMpin />,
                },
                {
                  path: "mobile-bill-payment",
                  element: <MobileBillPayment />,
                },
                {
                  path: "mobile-bill-confirm",
                  element: <MobileBillConfirm />,
                },
                {
                  path: "mobile-bill-mpin",
                  element: <MobileBillMpin />,
                },
                {
                  path: "internet-bill-payment",
                  element: <InternetBillPayment />,
                },
                {
                  path: "internet-bill-confirm",
                  element: <InternetBillBenefConfirm />,
                },
                {
                  path: "internet-bill-mpin",
                  element: <InternetBillMpin />,
                },
                {
                  path: "education-bill-payment",
                  element: <EducationBillPayment />,
                },
                {
                  path: "education-bill-confirm",
                  element: <EducationBillConfirm />,
                },
                {
                  path: "education-bill-mpin",
                  element: <EducationBillMpin />,
                },
                {
                  path: "insurance-bill-payment",
                  element: <InsuranceBillPayment />,
                },
                {
                  path: "insurance-bill-confirm",
                  element: <InsuranceBillConfirm />,
                },
                {
                  path: "insurance-bill-mpin",
                  element: <InsuranceBillMpin />,
                },
                {
                  path: "online-bill-payment",
                  element: <OnlineBillPayment />,
                },
                {
                  path: "online-bill-confirm",
                  element: <OnlineBillBenefConfirm />,
                },
                {
                  path: "online-bill-mpin",
                  element: <OnlineBillMpin />,
                },
                {
                  path: "online-bill-benef",
                  element: <OnlineBillBenef />,
                },
                {
                  path: "investment-bill-payment",
                  element: <InvestmentBillPayment />,
                },
                {
                  path: "investment-bill-confirm",
                  element: <InvestmentBillBenefConfirm />,
                },
                {
                  path: "investment-bill-mpin",
                  element: <InvestmentBillMpin />,
                },
                {
                  path: "government-bill-payment",
                  element: <GovernmentBillPayment />,
                },
                {
                  path: "government-bill-confirm",
                  element: <GovernmentBillBenefConfirm />,
                },
                {
                  path: "government-bill-mpin",
                  element: <GovernmentBillMpin />,
                },
                {
                  path: "other-bill-payment",
                  element: <OtherBillPayment />,
                },
                {
                  path: "other-bill-confirm",
                  element: <OtherBillBenefConfirm />,
                },
                {
                  path: "other-bill-mpin",
                  element: <OtherBillMpin />,
                },
                {
                  path: "credit-bill-payment",
                  element: <CreditBillPayment />,
                },
                {
                  path: "credit-bill-confirm",
                  element: <CreditBillBenefConfirm />,
                },
                {
                  path: "credit-bill-mpin",
                  element: <CreditBillMpin />,
                },
                {
                  path: "topup-bill-payment",
                  element: <TopupBillPayment />,
                },
                {
                  path: "topup-bill-confirm",
                  element: <TopupBillBenefConfirm />,
                },
                {
                  path: "topup-bill-mpin",
                  element: <TopupBillMpin />,
                },
                {
                  path: "voucher-bill-payment",
                  element: <VoucherBillPayment />,
                },
                {
                  path: "voucher-bill-confirm",
                  element: <VoucherBillBenefConfirm />,
                },
                {
                  path: "voucher-bill-mpin",
                  element: <VoucherBillMpin />,
                },
                {
                  path: "view-beneficiary",
                  element: <ViewBeneficiary />,
                },
              ],
            },
            {
              path: "beneficiary-payment",
              // element: <BeneficiaryPayment />,
              children: [
                {
                  element: <Navigate to={"view-beneficiary"} replace />,
                  index: true,
                },
                {
                  path: "view-beneficiary",
                  element: <ViewBeneficiaryPayment />,
                },
                {
                  path: "nbp-payment",
                  element: <NbpPayment />,
                },
                {
                  path: "confirm-nbp-payment",
                  element: <ConfirmNbpPayment />,
                },
                {
                  path: "nbp-mpin",
                  element: <NbpMpin />,
                },
                {
                  path: "raast-payment",
                  element: <RaastPayment />,
                },
                {
                  path: "raast-payment-confirm",
                  element: <RaastPaymentConfirm />,
                },
                {
                  path: "ibft-payment",
                  element: <IbftPayment />,
                },
                {
                  path: "confirm-ibft-payment",
                  element: <ConfirmIbftPayment />,
                },
                {
                  path: "ibft-mpin",
                  element: <IbftMpin />,
                },
                {
                  path: "bill-payment",
                  element: <BillPayment />,
                },
                {
                  path: "confirm-bill-payment",
                  element: <ConfirmBillPayment />,
                },
                {
                  path: "bill-mpin",
                  element: <BillMpin />,
                },
              ],
            },
          ],
        },
        {
          path: "debit-card",
          // element: <DebitCard />,
          children: [
            {
              element: <Navigate to={"card-manager"} replace />,
              index: true,
            },
            {
              path: "card-manager",
              element: <DebitCardManager />,
            },
            {
              path: "manage-card",
              element: <ManageDebitCard />,
            },
            {
              path: "card-details",
              element: <CardDetails />,
            },
            {
              path: "confirm-pin",
              element: <ChangeCardPin />,
            },
            {
              path: "change-pin-otp",
              element: <ChangeCardPinOtp />,
            },
            {
              path: "card-otp",
              element: <DebitCardOtp />,
            },
            {
              path: "request-card",
              element: <RequestNewCard />,
            },
            {
              path: "virtual-card",
              element: <VirtualCard />,
            },
          ],
        },
        {
          path: "e-transaction",
          element: <ETransaction />,
          children: [
            {
              element: <Navigate to={"e-transactions"} replace />,
              index: true,
            },
            {
              path: "e-transactions",
              element: <ETransactions />,
            },
            {
              path: "transfer-funds",
              element: <TransferFunds />,
            },
          ],
        },
      ],
    },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
// // Settings
const Settings = Loadable(lazy(() => import("../pages/Settings/Setting")));

const TermAndCondition = Loadable(
  lazy(() => import("../pages/TermsAndConditions/TermsAndConditions"))
);
const Faqs = Loadable(lazy(() => import("../pages/Faqs")));

const TandC = Loadable(lazy(() => import("../pages/Terms&Conditions")));
const Dashboard = Loadable(lazy(() => import("../pages/dashboard/Dashboard")));
const Complaints = Loadable(lazy(() => import("../pages/Complaints/index")));
const DonationList = Loadable(
  lazy(() => import("../pages/Donations/DoantionsList/index"))
);
const DonationPayment = Loadable(
  lazy(() => import("../pages/Donations/DonationForm/index"))
);
const SportsClub = Loadable(lazy(() => import("../pages/SportsClub/index")));

const ChangePassword = Loadable(
  lazy(() => import("../pages/Settings/changepassword/ChangePassword"))
);
const ChangeMpin = Loadable(
  lazy(() => import("../pages/Settings/changempin/ChangeMpin"))
);
const ForgotMpin = Loadable(
  lazy(() => import("../pages/Settings/forgotmpin/ForgotMpin"))
);
const ForgotMpinVerify = Loadable(
  lazy(() => import("../pages/Settings/forgotMpinVerify/index"))
);

const MyAccount = Loadable(lazy(() => import("../pages/my-account/MyAccount")));
const AddAccount = Loadable(
  lazy(() => import("../pages/my-account/AddAccount/FetchTitleAccount"))
);
const AddAccountMpin = Loadable(
  lazy(() => import("../pages/my-account/AddAccount/Mpin"))
);
const ViewAccout = Loadable(
  lazy(() => import("../pages/my-account/view-account/ViewAccount"))
);
const DormantAccount = Loadable(
  lazy(() => import("../pages/my-account/DormantAccounts/index"))
);
const DormantAccountMpin = Loadable(
  lazy(() => import("../pages/my-account/DormantAccounts/mpin"))
);
const ManageAccount = Loadable(
  lazy(() => import("../pages/my-account/manage-account/ManageAccount"))
);
const ViewLimit = Loadable(lazy(() => import("../pages/my-account/ViewLimit")));
const ManageLimit = Loadable(lazy(() => import("../pages/my-account/Limit")));
const LimitManagement = Loadable(
  lazy(() => import("../pages/my-account/LimitManagement"))
);
const LimitManagementMpin = Loadable(
  lazy(() => import("../pages/my-account/LimitManagement/mpin"))
);
const MyProfit = Loadable(
  lazy(() => import("../pages/my-account/Profit/index"))
);
// beneficairy management path starts here
const Beneficiary = Loadable(lazy(() => import("../pages/Beneficiary")));

const AddBeneficiary = Loadable(
  lazy(() => import("../pages/Beneficiary/AddBeneficiary"))
);
const EditBeneficiary = Loadable(
  lazy(() => import("../pages/Beneficiary/EditBeneficiary"))
);

const ViewBeneficiary = Loadable(
  lazy(() => import("../pages/Beneficiary/ViewBeneficiary"))
);

const AddRaastBeneficiary = Loadable(
  lazy(() => import("../pages/Beneficiary/RaastTransfer/Raast"))
);
const AddRaastBenefConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/RaastTransfer/Confirm"))
);
const AddRaastBenefMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/RaastTransfer/Mpin"))
);
const IntraBankTransfer = Loadable(
  lazy(() => import("../pages/Beneficiary/FundTransfer/IntraBankTransfer"))
);

const InterBankTransfer = Loadable(
  lazy(() => import("../pages/Beneficiary/IBFT/InterBankTransfer"))
);

const InterBankConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/IBFT/ConfirmDetails"))
);

const InterBankMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/IBFT/MPIN"))
);

const ElectricityBillPayment = Loadable(
  lazy(() =>
    import("../pages/Beneficiary/ElectrictyBill/ElectricityBillPayment")
  )
);
const ElectricityBillConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/ElectrictyBill/ConfirmDetails"))
);
const ElectricityBillMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/ElectrictyBill/MPIN"))
);

const GasBillPayment = Loadable(
  lazy(() => import("../pages/Beneficiary/GasBill/GasBillPayment"))
);
const GasBillConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/GasBill/ConfirmDetails"))
);
const GasBillMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/GasBill/MPIN"))
);

const WaterBillPayment = Loadable(
  lazy(() => import("../pages/Beneficiary/WaterBill/WaterBillPayment"))
);

const WaterBillConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/WaterBill/ConfirmDetails"))
);

const WaterBillMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/WaterBill/MPIN"))
);

const PtclBillPayment = Loadable(
  lazy(() => import("../pages/Beneficiary/PtclBill/PtclBillPayment"))
);

const PtclBillConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/PtclBill/ConfirmDetails"))
);

const PtclBillMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/PtclBill/MPIN"))
);
const MobileBillPayment = Loadable(
  lazy(() => import("../pages/Beneficiary/MobileBill/MobileBillPayment"))
);

const MobileBillConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/MobileBill/ConfirmDetails"))
);

const MobileBillMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/MobileBill/MPIN"))
);

const InternetBillPayment = Loadable(
  lazy(() => import("../pages/Beneficiary/InternetBill/InternetBillPayment"))
);

const InternetBillBenefConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/InternetBill/ConfirmDetails"))
);

const InternetBillMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/InternetBill/MPIN"))
);

const EducationBillPayment = Loadable(
  lazy(() => import("../pages/Beneficiary/EducationBill/EducationBillPayment"))
);

const EducationBillConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/EducationBill/ConfirmDetails"))
);

const EducationBillMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/EducationBill/MPIN"))
);

const InsuranceBillPayment = Loadable(
  lazy(() => import("../pages/Beneficiary/InsuranceBill/InsuranceBillPayment"))
);

const InsuranceBillConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/InsuranceBill/ConfirmDetails"))
);

const InsuranceBillMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/InsuranceBill/MPIN"))
);
const OnlineBillPayment = Loadable(
  lazy(() =>
    import("../pages/Beneficiary/OnlineShopping/OnlineShoppingPayment")
  )
);

const OnlineBillBenefConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/OnlineShopping/ConfirmDetails"))
);

const OnlineBillMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/OnlineShopping/MPIN"))
);

const OnlineBillBenef = Loadable(
  lazy(() =>
    import("../pages/Payments/OnlineBillPayments/OnlineBillBenef/index")
  )
);

const InvestmentBillPayment = Loadable(
  lazy(() => import("../pages/Beneficiary/Investment/InvestmentBillPayment"))
);

const InvestmentBillBenefConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/Investment/ConfirmDetails"))
);

const InvestmentBillMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/Investment/MPIN"))
);

const GovernmentBillPayment = Loadable(
  lazy(() => import("../pages/Beneficiary/Government/GovernmentBillPayment"))
);

const GovernmentBillBenefConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/Government/ConfirmDetails"))
);

const GovernmentBillMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/Government/MPIN"))
);

const OtherBillPayment = Loadable(
  lazy(() => import("../pages/Beneficiary/OtherBill/OtherBillPayment"))
);

const OtherBillBenefConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/OtherBill/ConfirmDetails"))
);

const OtherBillMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/OtherBill/MPIN"))
);
const CreditBillPayment = Loadable(
  lazy(() => import("../pages/Beneficiary/1BillCredit/CreditBillPayment"))
);

const CreditBillBenefConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/1BillCredit/ConfirmDetails"))
);

const CreditBillMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/1BillCredit/MPIN"))
);
const TopupBillPayment = Loadable(
  lazy(() => import("../pages/Beneficiary/1BillTopup/TopupBillPayment"))
);

const TopupBillBenefConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/1BillTopup/ConfirmDetails"))
);

const TopupBillMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/1BillTopup/MPIN"))
);
const VoucherBillPayment = Loadable(
  lazy(() => import("../pages/Beneficiary/1BillVoucher/VoucherBillPayment"))
);

const VoucherBillBenefConfirm = Loadable(
  lazy(() => import("../pages/Beneficiary/1BillVoucher/ConfirmDetails"))
);

const VoucherBillMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/1BillVoucher/MPIN"))
);

const FTConfirmDetails = Loadable(
  lazy(() => import("../pages/Beneficiary/FundTransfer/ConfirmDetails"))
);

const IntraBankMpin = Loadable(
  lazy(() => import("../pages/Beneficiary/FundTransfer/MPIN"))
);

// beneficairy management path ends here

// transfer path starts from here

const TransfersPages = Loadable(lazy(() => import("../pages/TransfersPages")));

const TransferFunds = Loadable(
  lazy(() => import("../pages/TransfersPages/TransferFunds/TransferFunds"))
);

// Beneficiary Payment starts from here

const BeneficiaryPayment = Loadable(
  lazy(() => import("../pages/BeneficiaryPayment"))
);

const ViewBeneficiaryPayment = Loadable(
  lazy(() => import("../pages/BeneficiaryPayment/ViewBeneficiaryPayment"))
);

const NbpPayment = Loadable(
  lazy(() => import("../pages/BeneficiaryPayment/NbpPayment"))
);

const ConfirmNbpPayment = Loadable(
  lazy(() => import("../pages/BeneficiaryPayment/NbpPaymentConfirm"))
);

const NbpMpin = Loadable(
  lazy(() => import("../pages/BeneficiaryPayment/NbpMpin"))
);
const RaastPayment = Loadable(
  lazy(() => import("../pages/BeneficiaryPayment/RaastPayment"))
);
const RaastPaymentConfirm = Loadable(
  lazy(() => import("../pages/BeneficiaryPayment/RaastPaymentConfirm"))
);

const IbftPayment = Loadable(
  lazy(() => import("../pages/BeneficiaryPayment/IbftPayment"))
);

const ConfirmIbftPayment = Loadable(
  lazy(() => import("../pages/BeneficiaryPayment/IbftPaymentConfirm"))
);

const IbftMpin = Loadable(
  lazy(() => import("../pages/BeneficiaryPayment/IbftMpin"))
);

const BillPayment = Loadable(
  lazy(() => import("../pages/BeneficiaryPayment/BillPayment"))
);

const ConfirmBillPayment = Loadable(
  lazy(() => import("../pages/BeneficiaryPayment/BillPaymentConfirm"))
);

const BillMpin = Loadable(
  lazy(() => import("../pages/BeneficiaryPayment/BillMpin"))
);

// Beneficiary Payment ends here

const ContactUs = Loadable(lazy(() => import("../pages/ContactUs/ContactUs")));

const ContactLogin = Loadable(
  lazy(() => import("../pages/ContactUs/ContactUs"))
);

const ETransaction = Loadable(lazy(() => import("../pages/ETransaction")));

const ETransactions = Loadable(
  lazy(() => import("../pages/ETransaction/ETransactions"))
);

const DebitCardManagement = Loadable(
  lazy(() =>
    import("../pages/DebitCardManagement/DebitCardManagement/DebitCard1")
  )
);

const SignIn = Loadable(
  lazy(() => import("../pages/SignIn/LogInPage/LogInPage"))
);

const NBPProducts = Loadable(lazy(() => import("../pages/SignIn/NBPProducts")));

const PasswordPage = Loadable(
  lazy(() => import("../pages/SignIn/PasswordPage/PasswordPage"))
);

const CnicForgotPass = Loadable(
  lazy(() => import("../pages/ForgotPassword/CnicPage/index"))
);

const ForgotPasswordL0 = Loadable(
  lazy(() => import("../pages/ForgotPassword/ForgotPassL0/index"))
);

const ForgotPasswordL2 = Loadable(
  lazy(() => import("../pages/ForgotPassword/ForgotPassL2/index"))
);
const Certificates = Loadable(
  lazy(() => import("../pages/Certificates/MainPage/index"))
);
const AccountMaintenance = Loadable(
  lazy(() => import("../pages/Certificates/AccountMaintenance"))
);
const WithHoldingTax = Loadable(
  lazy(() => import("../pages/Certificates/WithHoldingTax/index"))
);
const BalanceCertificate = Loadable(
  lazy(() => import("../pages/Certificates/BalanceCertificate/index"))
);

// payments section starts from here
const UtilityBillPayments = Loadable(
  lazy(() => import("../pages/Payments/UtilityBillPayments"))
);
const ElectrictyBillPayment = Loadable(
  lazy(() =>
    import("../pages/Payments/UtilityBillPayments/ElectricityBillPayments")
  )
);
const ElectrictyBillDetails = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/UtilityBillPayments/ElectricityBillPayments/ElectricityBillDetails"
    )
  )
);
const ElectrictyBillConfirm = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/UtilityBillPayments/ElectricityBillPayments/ElectricityBillConfirm"
    )
  )
);
const ElectrictyBillBenef = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/UtilityBillPayments/ElectricityBillPayments/ElectricityBillBenef"
    )
  )
);

const GasBillBenef = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/UtilityBillPayments/UtilityGasBillPayments/GasBillBenef/index"
    )
  )
);

const PtclBillBenef = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/UtilityBillPayments/UtilityPTCLBillPayments/PTCLBillBenef/index"
    )
  )
);
// Raast pages goes here

const Raast = Loadable(lazy(() => import("../pages/Raast")));
const RaastManagement = Loadable(
  lazy(() => import("../pages/Raast/ManageRaast/ManageRaast"))
);
const RegisterRaast = Loadable(
  lazy(() => import("../pages/Raast/RegisterRaast/RegisterRaast"))
);
const ConfirmRegister = Loadable(
  lazy(() => import("../pages/Raast/ConfirmRegister/ConfirmRegister"))
);
const LinkRaast = Loadable(
  lazy(() => import("../pages/Raast/LinkRaast/LinkRaast"))
);
const ConfirmLink = Loadable(
  lazy(() => import("../pages/Raast/ConfirmLink/ConfirmLink"))
);
const DelinkRaast = Loadable(
  lazy(() => import("../pages/Raast/DelinkRaast/DelinkRaast"))
);
const ConfirmDelink = Loadable(
  lazy(() => import("../pages/Raast/ConfirmDelink/ConfirmDelink"))
);
const RemoveRaast = Loadable(
  lazy(() => import("../pages/Raast/RemoveRaast/RemoveRaast"))
);
const ConfirmRemove = Loadable(
  lazy(() => import("../pages/Raast/ConfirmRemove/ConfirmRemove"))
);

const FundTransfer = Loadable(
  lazy(() => import("../pages/TransfersPages/FundTransfer/FundTransfer"))
);
const FundTransferBenef = Loadable(
  lazy(() => import("../pages/TransfersPages/FundTransferBenef"))
);
const IbftTransferBenef = Loadable(
  lazy(() => import("../pages/TransfersPages/IbftTransferBenef"))
);
const FundTransferMpin = Loadable(
  lazy(() => import("../pages/TransfersPages/FundTransferMpin/MPIN"))
);

const InterBankFundTransfer = Loadable(
  lazy(() =>
    import(
      "../pages/TransfersPages/InterBankFundTransfer/InterBankFundTransfer"
    )
  )
);

const IbftTransferMpin = Loadable(
  lazy(() => import("../pages/TransfersPages/IbftTransferMpin/MPIN"))
);
const RaastTransfer = Loadable(
  lazy(() => import("../pages/TransfersPages/RaastTransfer"))
);
const RaastTransferConfirm = Loadable(
  lazy(() => import("../pages/TransfersPages/RaastTransferConfirm"))
);
const RaastTransferMpin = Loadable(
  lazy(() => import("../pages/TransfersPages/RaastTransferMpin"))
);
const RaastTransferBenef = Loadable(
  lazy(() => import("../pages/TransfersPages/RaastTransferBeneficiary"))
);
const CnicTransfer = Loadable(
  lazy(() => import("../pages/TransfersPages/CnicTransfer/CnicTransfer"))
);

const TransferMPIN = Loadable(
  lazy(() => import("../pages/TransfersPages/CnicTransferMPIN/MPIN"))
);

const ConfirmDetail = Loadable(
  lazy(() => import("../pages/TransfersPages/ConfirmDetail/ConfirmDetail"))
);

const ConfirmIbftDetail = Loadable(
  lazy(() =>
    import("../pages/TransfersPages/ConfirmIbftDetail/ConfirmIbftDetail")
  )
);

const ConfirmCnicDetail = Loadable(
  lazy(() =>
    import("../pages/TransfersPages/ConfirmCnicDetail/ConfirmCnicDetail")
  )
);
// NBP Upgrade Registration Pages

const UpgradeTerms = Loadable(
  lazy(() => import("../pages/NBPUpgradeRegistration/TermAndCondition/index"))
);

const DebitPin = Loadable(
  lazy(() => import("../pages/NBPUpgradeRegistration/UpgradeDebitPin/DebitPin"))
);

const UpgradeRegistration = Loadable(
  lazy(() =>
    import(
      "../pages/NBPUpgradeRegistration/UpgradeRegistration/UpgradeRegistration"
    )
  )
);

// terms and conditions and register pages goes from here

const TermsPage = Loadable(
  lazy(() => import("../pages/RegisterPage/RegisterPageOne/RegisterPageOne"))
);

const RegisterAccount = Loadable(
  lazy(() =>
    import(
      "../pages/RegisterPage/RegisterAccountDetails/RegisterAccountDetails"
    )
  )
);
const RegisterOtp = Loadable(lazy(() => import("../pages/OTPPage/OTPPage")));
const RegisterMpin = Loadable(
  lazy(() => import("../pages/RegisterPage/RegisterMpin/RegisterMpin"))
);
const ChangePassL0 = Loadable(
  lazy(() => import("../pages/ChangePassword/ChangePassL0"))
);
const ChangePassL2 = Loadable(
  lazy(() => import("../pages/ChangePassword/ChangePassL2"))
);
const InvestStocks = Loadable(
  lazy(() => import("../pages/InvestStocks/index"))
);
const InvestMutualFunds = Loadable(
  lazy(() => import("../pages/InvestMutualFunds/index"))
);
const InvestGold = Loadable(lazy(() => import("../pages/InvestGold/index")));
const RaastQR = Loadable(lazy(() => import("../pages/RaastQR/StaticQR/index")));
const DynamicQR = Loadable(
  lazy(() => import("../pages/RaastQR/DynamicQR/index"))
);

// Debit card routes goes from here
const DebitCard = Loadable(lazy(() => import("../pages/DebitCard")));
const DebitCardManager = Loadable(
  lazy(() => import("../pages/DebitCard/DebitCardManagement"))
);
const RequestNewCard = Loadable(
  lazy(() => import("../pages/DebitCard/RequestNewCard"))
);
const VirtualCard = Loadable(
  lazy(() => import("../pages/DebitCard/AddVirtualCard"))
);
const DebitCardOtp = Loadable(
  lazy(() => import("../pages/DebitCard/DebitCardOtp/index"))
);
const ManageDebitCard = Loadable(
  lazy(() => import("../pages/DebitCard/ManageCard"))
);
const CardDetails = Loadable(
  lazy(() => import("../pages/DebitCard/CardDetails"))
);
const ChangeCardPin = Loadable(
  lazy(() => import("../pages/DebitCard/ChangePin"))
);
const ChangeCardPinOtp = Loadable(
  lazy(() => import("../pages/DebitCard/ChangePinOtp"))
);

const UtilityGasBillPayment = Loadable(
  lazy(() =>
    import("../pages/Payments/UtilityBillPayments/UtilityGasBillPayments")
  )
);
const UtilityGasBillDetails = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/UtilityBillPayments/UtilityGasBillPayments/UtilityGasBillDetails"
    )
  )
);
const UtilityGasBillConfirm = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/UtilityBillPayments/UtilityGasBillPayments/UtilityGasBillConfirm"
    )
  )
);
const UtilityGasBillMpin = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/UtilityBillPayments/UtilityGasBillPayments/PaymentsMPIN"
    )
  )
);

const UtilityWaterBillPayment = Loadable(
  lazy(() =>
    import("../pages/Payments/UtilityBillPayments/UtilityWaterBillPayments")
  )
);

const WaterBillBenef = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/UtilityBillPayments/UtilityWaterBillPayments/WaterBillBenef/index"
    )
  )
);
const UtilityWaterBillDetails = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/UtilityBillPayments/UtilityWaterBillPayments/UtilityWaterBillDetails"
    )
  )
);
const UtilityWaterBillConfirm = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/UtilityBillPayments/UtilityWaterBillPayments/UtilityWaterBillConfirm"
    )
  )
);
const UtilityWaterBillMpin = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/UtilityBillPayments/UtilityWaterBillPayments/PaymentsMPIN"
    )
  )
);

const UtilityPtclBillMpin = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/UtilityBillPayments/UtilityPTCLBillPayments/PaymentsMPIN"
    )
  )
);

const UtilityPTCLBillPayment = Loadable(
  lazy(() =>
    import("../pages/Payments/UtilityBillPayments/UtilityPTCLBillPayments")
  )
);
const UtilityPTCLBillDetails = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/UtilityBillPayments/UtilityPTCLBillPayments/UtilityPTCLBillDetails"
    )
  )
);
const UtilityPTCLBillConfirm = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/UtilityBillPayments/UtilityPTCLBillPayments/UtilityPTCLBillConfirm"
    )
  )
);

const MobileBillPayments = Loadable(
  lazy(() => import("../pages/Payments/MobileBillPayments"))
);

const ZongBillPayment = Loadable(
  lazy(() => import("../pages/Payments/MobileBillPayments/ZongBillPayments"))
);
const ZongBillDetails = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/MobileBillPayments/ZongBillPayments/ZongBillDetails"
    )
  )
);
const ZongBillConfirm = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/MobileBillPayments/ZongBillPayments/ZongBillConfirm"
    )
  )
);
const ZongBillMpin = Loadable(
  lazy(() =>
    import("../pages/Payments/MobileBillPayments/ZongBillPayments/PaymentsMPIN")
  )
);

const ZongBillBenef = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/MobileBillPayments/ZongBillPayments/ZongBillBenef/index"
    )
  )
);

const UfoneBillPayment = Loadable(
  lazy(() => import("../pages/Payments/MobileBillPayments/UfoneBillPayments"))
);
const UfoneBillDetails = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/MobileBillPayments/UfoneBillPayments/UfoneBillDetails"
    )
  )
);
const UfoneBillConfirm = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/MobileBillPayments/UfoneBillPayments/UfoneBillConfirm"
    )
  )
);
const UfoneBillMpin = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/MobileBillPayments/UfoneBillPayments/UfoneBillMpin"
    )
  )
);

const UfoneBillBenef = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/MobileBillPayments/UfoneBillPayments/UfoneBillBenef/index"
    )
  )
);

const JazzBillPayment = Loadable(
  lazy(() => import("../pages/Payments/MobileBillPayments/JazzBillPayments"))
);
const JazzBillDetails = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/MobileBillPayments/JazzBillPayments/JazzBillDetails"
    )
  )
);
const JazzBillConfirm = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/MobileBillPayments/JazzBillPayments/JazzBillConfirm"
    )
  )
);
const JazzBillMpin = Loadable(
  lazy(() =>
    import("../pages/Payments/MobileBillPayments/JazzBillPayments/PaymentsMPIN")
  )
);

const JazzBillBenef = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/MobileBillPayments/JazzBillPayments/JazzBillBenef/index"
    )
  )
);

const TelenorBillPayment = Loadable(
  lazy(() => import("../pages/Payments/MobileBillPayments/TelenorBillPayments"))
);
const TelenorBillDetails = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/MobileBillPayments/TelenorBillPayments/TelenorBillDetails"
    )
  )
);
const TelenorBillConfirm = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/MobileBillPayments/TelenorBillPayments/TelenorBillConfirm"
    )
  )
);
const TelenorBillMpin = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/MobileBillPayments/TelenorBillPayments/PaymentsMPIN"
    )
  )
);

const TelenorBillBenef = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/MobileBillPayments/TelenorBillPayments/TelenorBillBenef/index"
    )
  )
);

const PaymentsMPIN = Loadable(
  lazy(() =>
    import(
      "../pages/Payments/UtilityBillPayments/ElectricityBillPayments/PaymentsMPIN"
    )
  )
);

const InternetBillPayments = Loadable(
  lazy(() => import("../pages/Payments/InternetBillPayments"))
);

const InternetBillDetails = Loadable(
  lazy(() =>
    import(
      // "../pages/Payments/InternetBillPayments/NetBillPayments/NetBillTable"
      "../pages/Payments/InternetBillPayments/InternetBillDetails"
    )
  )
);
const InternetBillConfirm = Loadable(
  lazy(() =>
    import("../pages/Payments/InternetBillPayments/InternetBillConfirm")
  )
);
const InternetBillPaymentMpin = Loadable(
  lazy(() => import("../pages/Payments/InternetBillPayments/PaymentsMPIN"))
);

const InternetBillBenef = Loadable(
  lazy(() =>
    import("../pages/Payments/InternetBillPayments/InternetBillBenef/index")
  )
);

const NetBillConfirm = Loadable(
  lazy(() =>
    // import(
    //   "../pages/Payments/InternetBillPayments/NetBillPayments/NetBillConfirm"
    // )
    import("../pages/Payments/InternetBillPayments/InternetBillConfirm")
  )
);

const OnlineBillPayments = Loadable(
  lazy(() => import("../pages/Payments/OnlineBillPayments"))
);

// const OLBillPayments = Loadable(
//   lazy(() => import("../pages/Payments/OnlineBillPayments/OLBillPayments"))
// );

const OnlineBillTable = Loadable(
  lazy(() => import("../pages/Payments/OnlineBillPayments/OnlineBillTable"))
);

const OnlineBillForm = Loadable(
  lazy(() => import("../pages/Payments/OnlineBillPayments/OnlineBillForm"))
);

const OnlineBillConfirm = Loadable(
  lazy(() => import("../pages/Payments/OnlineBillPayments/OnlineBillConfirm"))
);

const OnlineBillPaymentMpin = Loadable(
  lazy(() => import("../pages/Payments/OnlineBillPayments/PaymentsMPIN"))
);
// const OnlineBillTable = Loadable(
//   lazy(() => import("../pages/Payments/OnlineBillPayments/OLBillPayments/OnlineBillTable"))
// );

// const OnlineBillForm = Loadable(
//   lazy(() => import("../pages/Payments/OnlineBillPayments/OLBillPayments/OnlineBillForm"))
// );

// const OnlineBillConfirm = Loadable(
//   lazy(() => import("../pages/Payments/OnlineBillPayments/OnlineBillConfirm"))
// );

const OnlineBillDetails = Loadable(
  lazy(() => import("../pages/Payments/OnlineBillPayments/OnlineBillDetails"))
);

const EducationBillPayments = Loadable(
  lazy(() => import("../pages/Payments/EducationBillPayments"))
);

const EducationBillPaymentsConfirm = Loadable(
  lazy(() =>
    import("../pages/Payments/EducationBillPayments/EducationBillConfirm")
  )
);
const EducationBillPaymentsBenef = Loadable(
  lazy(() =>
    import("../pages/Payments/EducationBillPayments/EducationBillBenef")
  )
);

const EducationBillPaymentMpin = Loadable(
  lazy(() => import("../pages/Payments/EducationBillPayments/PaymentsMPIN"))
);
const AssetBillPayments = Loadable(
  lazy(() => import("../pages/Payments/AssetManagement"))
);

const AssetBillPaymentsConfirm = Loadable(
  lazy(() => import("../pages/Payments/AssetManagement/AssetManagementConfirm"))
);

const AssetBillPaymentMpin = Loadable(
  lazy(() => import("../pages/Payments/AssetManagement/PaymentsMPIN"))
);

const FinancialBillPayments = Loadable(
  lazy(() => import("../pages/Payments/Financial Service"))
);

const FinancialPaymentsConfirm = Loadable(
  lazy(() =>
    import("../pages/Payments/Financial Service/FinancialServiceConfirm")
  )
);

const FinancialBillPaymentMpin = Loadable(
  lazy(() => import("../pages/Payments/Financial Service/PaymentsMPIN"))
);

const InsuranceBillPayments = Loadable(
  lazy(() => import("../pages/Payments/InsuranceBillPayments"))
);

const InsuranceBillPaymentsConfirm = Loadable(
  lazy(() =>
    import("../pages/Payments/InsuranceBillPayments/InsuranceBillConfirm")
  )
);

const InsuranceBillPaymentMpin = Loadable(
  lazy(() => import("../pages/Payments/InsuranceBillPayments/PaymentsMPIN"))
);

const InsuranceBillBenef = Loadable(
  lazy(() =>
    import("../pages/Payments/InsuranceBillPayments/InsuranceBillBenef/index")
  )
);

const InvestmentBillPayments = Loadable(
  lazy(() => import("../pages/Payments/InvestmentBillPayments"))
);

const InvestmentBillPaymentsConfirm = Loadable(
  lazy(() =>
    import("../pages/Payments/InvestmentBillPayments/InvestmentBillConfirm")
  )
);

const InvestmentBillPaymentMpin = Loadable(
  lazy(() => import("../pages/Payments/InvestmentBillPayments/PaymentsMPIN"))
);

const InvestmentBillBenef = Loadable(
  lazy(() =>
    import("../pages/Payments/InvestmentBillPayments/InvestmentBillBenef/index")
  )
);

const GovernmentBillPayments = Loadable(
  lazy(() => import("../pages/Payments/GovernmentBillPayments"))
);

const GovernmentBillPaymentsBenef = Loadable(
  lazy(() =>
    import("../pages/Payments/GovernmentBillPayments/GovernmentBillBenef")
  )
);

const GovernmentBillPaymentsConfirm = Loadable(
  lazy(() =>
    import("../pages/Payments/GovernmentBillPayments/GovernmentBillConfirm")
  )
);

const GovernmentBillPaymentMpin = Loadable(
  lazy(() => import("../pages/Payments/GovernmentBillPayments/PaymentsMPIN"))
);

const PsidGeneration = Loadable(
  lazy(() => import("../pages/Payments/GovernmentBillPayments/PsidForm"))
);

const OtherBillPayments = Loadable(
  lazy(() => import("../pages/Payments/OtherBillPayments"))
);

const OtherBillPaymentsConfirm = Loadable(
  lazy(() => import("../pages/Payments/OtherBillPayments/OtherBillConfirm"))
);

const OtherBillPaymentMpin = Loadable(
  lazy(() => import("../pages/Payments/OtherBillPayments/PaymentsMPIN"))
);

const OtherBillBenef = Loadable(
  lazy(() => import("../pages/Payments/OtherBillPayments/OtherBillBenef/index"))
);

const CreditBillPayments = Loadable(
  lazy(() => import("../pages/Payments/1BillCreditPayments"))
);

const CreditBillPaymentsConfirm = Loadable(
  lazy(() => import("../pages/Payments/1BillCreditPayments/CreditBillConfirm"))
);

const CreditBillPaymentBenef = Loadable(
  lazy(() => import("../pages/Payments/1BillCreditPayments/CreditBillBenef"))
);
const CreditBillPaymentMpin = Loadable(
  lazy(() => import("../pages/Payments/1BillCreditPayments/PaymentsMPIN"))
);
const TopupBillPayments = Loadable(
  lazy(() => import("../pages/Payments/1BillTopupPayments"))
);

const TopupBillPaymentsConfirm = Loadable(
  lazy(() => import("../pages/Payments/1BillTopupPayments/TopupBillConfirm"))
);

const TopupBillPaymentsBenef = Loadable(
  lazy(() => import("../pages/Payments/1BillTopupPayments/TopupBillBenef"))
);

const TopupBillPaymentMpin = Loadable(
  lazy(() => import("../pages/Payments/1BillTopupPayments/PaymentsMPIN"))
);
const VoucherBillPayments = Loadable(
  lazy(() => import("../pages/Payments/1BillVoucherPayments"))
);

const VoucherBillPaymentsConfirm = Loadable(
  lazy(() =>
    import("../pages/Payments/1BillVoucherPayments/VoucherBillConfirm")
  )
);
const VoucherBillPaymentsBenef = Loadable(
  lazy(() => import("../pages/Payments/1BillVoucherPayments/VoucherBillBenef"))
);

const VoucherBillPaymentMpin = Loadable(
  lazy(() => import("../pages/Payments/1BillVoucherPayments/PaymentsMPIN"))
);

// set route for login otp

const LoginOTP = Loadable(lazy(() => import("../pages/SignIn/LoginOTP")));
const LoginMpin = Loadable(lazy(() => import("../pages/SignIn/LoginMpin")));

import * as React from "react";
import { useState } from "react";
import { Navbar } from "../Navbar";
import { Sidebar } from "../SidebarComponent";
import nbpLogo from "../../stories/assets/NBPLogoV.svg";
import {
  MainBody,
  SidebarDiv,
  ChildrenDiv,
  ImagesDiv,
  BodyDiv,
  TitleDiv,
  MainPageDiv,
  CardDiv,
  RightBarDiv,
  RaastMainDiv,
  RaastInnerDiv,
  RaastOval,
  CopyDiv,
  DrawerSidebarDiv,
  StyledIconContainer,
  StyledIcon,
  FixedSidebar,
  BodyDivWrapper,
} from "./styles";
import { ImageCarousel } from "../Images";
import { Title } from "../Title";
import { WrapperTypes } from "./types";
import { RiDashboardLine } from "react-icons/ri";
import {
  MdAccountBox,
  MdOutlineAccountBalance,
  MdOutlineSupervisorAccount,
  MdPayments,
} from "react-icons/md";
import { BiCard, BiCopy, BiTransferAlt } from "react-icons/bi";
import { AiFillPlusCircle, AiOutlineDollarCircle } from "react-icons/ai";
import { HiOutlineCreditCard } from "react-icons/hi";
import { IoSettingsOutline } from "react-icons/io5";
import { GetLoginData } from "../../Api/Login";
import { useLocation, useNavigate } from "react-router-dom";
import AlertModal from "../../components/AlertModal";
import { Box, Drawer, Grid, useMediaQuery } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { PostLogoutData } from "../../Api/GeneralApi";
import { GetTokenData } from "../../Api/Token";
import queryClient from "../../Api/queryClient";
import { useQueryClient } from "react-query";
import { consoleLog } from "../../helper/consoleLogs";
import RaastModal from "../../components/RaastModal";
import DrawerContent from "../../components/DrawerContent";
import { GetDashboardAccountData } from "../../Api/DashboardApi";

export const WrapperComponent = ({
  children,

  showRaastID,
  raastIdPresent,
}: WrapperTypes) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to control the sidebar
  const [inactiveModal, setInactiveModal] = useState(false);
  const ReactQueryClient = useQueryClient();
  const tokenData = GetTokenData();
  const loginData = GetLoginData();
  const dashboardData = GetDashboardAccountData();
  const navigate = useNavigate();
  const [showRaastPopup, setShowRaastPopup] = useState(false);
  const [showBvsPopup, setShowBvsPopup] = useState(false);
  const location = useLocation();

  const bvsStatus = loginData?.data?.data?.details?.bvsStatus;

  React.useEffect(() => {
    setShowRaastPopup(loginData?.data?.data?.details?.raastPopup);
  }, [loginData]);

  React.useEffect(() => {
    if (!showRaastPopup && tokenData) {
      if (!bvsStatus) {
        setShowBvsPopup(true);
      }
    }
  }, [showRaastPopup]);

  const {
    mutate: LogoutDataMutate,
    isLoading: LogoutDataIsLoading,
    isError: LogoutDataIsError,
    data: LogoutData,
    error: LogoutDataError,
  } = PostLogoutData();

  const handleLogout = () => {
    if (tokenData) {
      LogoutDataMutate({ token: tokenData });
    } else {
      queryClient.clear();
      ReactQueryClient.clear();
      navigate("/login");
    }
  };

  React.useEffect(() => {
    if (LogoutData?.data?.responseCode) {
      queryClient.clear();
      ReactQueryClient.clear();
      consoleLog("LogoutDataData in navbar component", LogoutData);
      navigate("/login");
    }
  }, [LogoutData]);

  React.useEffect(() => {
    if (!tokenData) {
      setInactiveModal(true);
    }
  }, [tokenData]);

  const copyToClipboard = (text: any) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };

  const handleCopyClick = () => {
    // consoleLog("saba");
    setIsCopied(true);
    setIsModalOpen(true);

    const raastID = loginData?.data?.data?.details?.mobile;

    copyToClipboard(raastID);
    setTimeout(() => {
      setIsCopied(false);
      setIsModalOpen(false);
    }, 2000);
  };

  const handleClickAway = () => {
    setIsSidebarOpen(false);
  };
  const links = [
    { title: "FAQs", path: "/" },
    { title: "Terms and Conditons", path: "/term-and-condition" },
    { title: "Contact Us", path: "/contact-us" },
  ];

  const MenuItems = [
    {
      title: "Dashboard",
      leftIcon: <RiDashboardLine size={32} />,
      path: "/app",
      icon: "/assets/dashboard.png",
    },
    {
      title: "Account Management",
      leftIcon: <MdAccountBox size={32} />,
      // path: "/my-account",
      icon: "/assets/account.png",
      subMenu: [
        {
          title: "View Account",
          path: "/my-account/view-account",
        },
        {
          title: "Add Account",
          path: "/my-account/add-account",
        },
        {
          title: "Manage Account",
          path: "/my-account/manage-account",
        },
        {
          title: "Certificates",
          path: "/certificates",
        },
        // {
        //   title: "Limit Management",
        //   path: "/my-account/manage-limit",
        // },

        // {
        //   title: "Dormant Account",
        //   path: "/my-account/dormant-account",
        // },
      ],
    },
    // {
    //   title: "Transfers",
    //   leftIcon: <BiTransferAlt size={32} />,
    //   // path: "/transfer",
    //   icon: "/assets/transfers.png",
    //   subMenu: [
    //     // {
    //     //   title: "Fund Transfer",
    //     //   path: "/transfer/fund-transfer",
    //     // },
    //     {
    //       title: "Funds Transfer",
    //       path: "/transfer/Inter-Bank-fund-transfer",
    //     },
    //     {
    //       title: "CNIC Transfer",
    //       path: "/transfer/cnic-transfer",
    //     },
    //     {
    //       title: "Raast Transfer",
    //       path: "/transfer/raast-transfer",
    //     },
    //   ],
    // },
    // {
    //   title: "Certificates",
    //   leftIcon: <RiDashboardLine size={32} />,
    //   path: "/certificates",
    //   icon: "/assets/certificateswhite.png",
    // },
    // {
    //   title: "Payments",
    //   leftIcon: <AiOutlineDollarCircle size={32} />,
    //   // path: "/payments",
    //   icon: "/assets/payments.png",
    //   subMenu: [
    //     {
    //       title: "Utility Bill Payments",
    //       path: "/payments/utility-bill-payments",
    //     },
    //     {
    //       title: "Mobile Bill Payments",
    //       path: "/payments/mobile-bill-payments",
    //     },
    //     {
    //       title: "Internet Bill Payments",
    //       path: "/payments/internet-payments",
    //     },
    //     {
    //       title: "Online Shopping",
    //       path: "/payments/online-payments",
    //     },
    //     {
    //       title: "Education Payments",
    //       path: "/payments/education-payments",
    //     },
    //     // {
    //     //   title: "Asset Management",
    //     //   path: "/payments/asset-payments",
    //     // },
    //     {
    //       title: "1 Bill Credit Card Bill",
    //       path: "/payments/credit-payments",
    //     },
    //     {
    //       title: "Insurance Payments",
    //       path: "/payments/insurance-payments",
    //     },
    //     {
    //       title: "Investments",
    //       path: "/payments/investment-payments",
    //     },
    //     {
    //       title: "1 Bill Top Up",
    //       path: "/payments/topup-payments",
    //     },
    //     {
    //       title: "1 Bill Voucher/ Fee Payments",
    //       path: "/payments/voucher-payments",
    //     },
    //     {
    //       title: "Government Payments",
    //       path: "/payments/government-payments",
    //     },
    //     {
    //       title: "Other Payments",
    //       path: "/payments/other-payments",
    //     },
    //   ],
    // },
    {
      title: "Raast ID Management",
      leftIcon: <MdOutlineAccountBalance size={32} />,
      icon: "/assets/raastwhite.png",
      path: "/raast/manage-raast",
    },
    // {
    //   title: "Beneficiary Management",
    //   leftIcon: <MdOutlineSupervisorAccount size={32} />,
    //   icon: "/assets/benef.png",
    //   subMenu: [
    //     {
    //       title: "Add Beneficiary",
    //       path: "/beneficiary/add-beneficiary",
    //     },
    //     {
    //       title: "View Beneficiary",
    //       path: "/beneficiary/view-beneficiary",
    //     },
    //   ],
    // },
    {
      title: "Beneficiary Payments",
      leftIcon: <MdPayments size={32} />,
      icon: "/assets/bank-copy-2.svg",

      path: "/beneficiary-payment/view-beneficiary",
    },
    {
      title: "Card Management",
      leftIcon: <HiOutlineCreditCard size={32} />,
      icon: "/assets/payments-copy.svg",
      path: "/debit-card/card-manager",
      // subMenu: [
      //   {
      //     title: "Debit Card",
      //     path: "/debit-card/card-manager",
      //   },
      //   {
      //     title: "Digital Debit Card",
      //     path: "/debit-card/virtual-card",
      //   },
      // ],
    },
    // {
    //   title: "Invest in Stocks",
    //   leftIcon: <BiCard size={32} />,
    //   icon: "/assets/stock.png",
    //   path: "/invest-stocks",
    // },
    {
      title: "Investments",
      leftIcon: <BiCard size={32} />,
      // path: "/payments",
      icon: "/assets/stock.png",
      subMenu: [
        {
          title: "Invest in Stocks",
          path: "/invest-stocks",
        },
        {
          title: "Invest in Gold",
          path: "/invest-gold",
        },
        // {
        //   title: "Invest in Mutual Funds",
        //   path: "/invest-mutual-fund",
        // },
      ],
    },
    {
      title: "Raast QR",
      leftIcon: <BiCard size={32} />,
      icon: "/assets/QRaaast2.png",
      path: "/raast-qr/static-qr",
    },

    {
      title: "E-Receipt",
      leftIcon: <BiCard size={32} />,
      icon: "/assets/ETransaction.png",
      path: "/e-transaction/e-transactions",
    },

    {
      title: "Complaints",
      leftIcon: <HiOutlineCreditCard size={32} />,
      icon: "/assets/ComplaintIconWhite.png",
      path: "/complaints",
    },
    {
      title: "Donations",
      leftIcon: <HiOutlineCreditCard size={32} />,
      icon: "/assets/donation.png",
      path: "/donations-list",
    },
    {
      title: "NBP Sports Club Fee",
      leftIcon: <HiOutlineCreditCard size={32} />,
      icon: "/assets/football.png",
      path: "/sports-club-fee",
    },
    {
      title: "Settings",
      leftIcon: <IoSettingsOutline size={32} />,
      icon: "/assets/settings.svg",
      subMenu: [
        {
          title: "Change Password",
          path: "/settings/change-password",
        },
        // {
        //   title: "Change MPIN",
        //   path: "/settings/change-mpin",
        // },
        // {
        //   title: "Forgot MPIN",
        //   path: "/settings/forgot-mpin-verify",
        // },
      ],
    },
  ];
  const [items, setItems] = useState(MenuItems);
  // Find the index of "My Account" in the items array to know where to add the "Profits" submenu
  const myAccountIndex = items.findIndex((item) => item.title === "My Account");

  // React.useEffect(() => {
  //   if (dashboardData?.data?.data?.accounts?.accountType.includes("Savings")) {
  //     consoleLog("it is saving account");
  //     // Define the Profits menu item
  //     const profitsMenuItem = {
  //       title: "Profits",
  //       path: "/my-account/profits",
  //     };
  //     // Correctly update the items state
  //     setItems((items) => {
  //       // Clone the items array to avoid direct mutation
  //       const newItems: any = [...items];

  //       // Find the "My Account" item and clone its subMenu array to avoid direct mutation
  //       if (newItems[myAccountIndex] && newItems[myAccountIndex].subMenu) {
  //         const newSubMenu = [
  //           ...newItems[myAccountIndex].subMenu,
  //           profitsMenuItem,
  //         ]; // Add the new submenu item
  //         newItems[myAccountIndex] = {
  //           ...newItems[myAccountIndex],
  //           subMenu: newSubMenu,
  //         }; // Update the "My Account" item with the new submenu
  //       }

  //       return newItems; // Return the updated items array
  //     });
  //   }
  // }, [dashboardData, myAccountIndex]);

  consoleLog("items", items);
  const onClose = () => {
    setIsModalOpen(false);
  };
  const toggleDrawer = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event?.target === event?.currentTarget) {
      // This condition checks if the click occurred on the backdrop of the Drawer
      setIsSidebarOpen(false); // Close the drawer when clicking outside (on the backdrop)
    }
  };
  React.useEffect(() => {
    setIsSidebarOpen(false);
  }, [location.pathname]);
  const isMobile = useMediaQuery("(max-width:1024px)");

  return (
    <MainPageDiv>
      <AlertModal
        responseCode="00"
        open={inactiveModal}
        setSuccessSubmit={handleLogout}
      >
        Dear Customer, Your session is expired. Please Login again
      </AlertModal>
      <AlertModal
        responseCode="00"
        open={showBvsPopup}
        setSuccessSubmit={() => setShowBvsPopup(false)}
      >
        Biometric Verification of your account is not found due to which you
        have limited access to transactions. Please visit NBP Branch to update
        your Biometric records. Login and upgrade your registration once your
        record is updated.
      </AlertModal>
      <RaastModal
        open={showRaastPopup}
        onClose={() => setShowRaastPopup(false)}
        setSuccessSubmit={() => {
          setShowRaastPopup(false);
          navigate("/raast");
        }}
      >
        Create/Register your Raast ID by Linking your Registered Mobile Number
        with your account. Your Mobile Number will be your Raast ID to receive
        funds from other banks.
      </RaastModal>
      <MainBody>
        {isMobile && (
          <>
            <div
              style={{
                position: "fixed",
                top: 0,
                zIndex: "10",
                width: "100%",
              }}
            >
              <Navbar
                variant="Primary"
                links={links}
                logoSrc={nbpLogo}
                onMenuIconClick={() => setIsSidebarOpen(!isSidebarOpen)}
              />
            </div>
          </>
        )}
        <Drawer anchor="left" open={isSidebarOpen} onClose={toggleDrawer}>
          <Box
            sx={{ width: "50%" }}
            role="presentation"
            // onClick={toggleDrawer}
            // onKeyDown={toggleDrawer}
          >
            <DrawerSidebarDiv>
              {/* <DrawerContent /> */}
              <Sidebar menu={items} logoSrc={nbpLogo} />
            </DrawerSidebarDiv>
          </Box>
        </Drawer>
        {!isMobile && (
          <SidebarDiv>
            <Sidebar menu={items} logoSrc={nbpLogo} />
          </SidebarDiv>
        )}
        <BodyDivWrapper>
          <BodyDiv>
            <div style={{ position: "sticky", top: 0, zIndex: "10" }}>
              {!isMobile && (
                <Navbar variant="Primary" links={links} logoSrc={nbpLogo} />
              )}
            </div>
            <Grid container spacing={4}>
              <Grid item xs={isMobile ? 12 : 9}>
                {children}
              </Grid>
              <Grid item xs={3}>
                {!isMobile && (
                  <RightBarDiv>
                    <ImagesDiv>
                      <ImageCarousel />
                    </ImagesDiv>
                    {showRaastID ? (
                      <RaastMainDiv>
                        <h4 style={{ margin: 0 }}>Raast ID</h4>
                        <RaastInnerDiv onClick={() => navigate("/raast")}>
                          <StyledIconContainer>
                            <StyledIcon>
                              <img
                                src="/assets/Raast-transparent.png"
                                alt="raast"
                              />{" "}
                            </StyledIcon>
                          </StyledIconContainer>
                          Create Raast ID
                        </RaastInnerDiv>
                      </RaastMainDiv>
                    ) : null}
                    {raastIdPresent ? (
                      <RaastMainDiv>
                        <h4 style={{ margin: 0 }}>Raast ID</h4>
                        <RaastInnerDiv>
                          <StyledIconContainer>
                            <StyledIcon>
                              <img
                                src="/assets/Raast-transparent.png"
                                alt="raast"
                              />{" "}
                            </StyledIcon>
                          </StyledIconContainer>
                          <h3 style={{ margin: 0 }}>
                            {loginData?.data?.data?.details?.accountTitle
                              ?.split(" ")
                              .slice(0, 2)
                              .join(" ")}
                          </h3>
                          <div>{loginData?.data?.data?.details?.mobile}</div>
                          <CopyDiv onClick={handleCopyClick}>
                            Copy <BiCopy size="15px" />
                          </CopyDiv>
                        </RaastInnerDiv>
                      </RaastMainDiv>
                    ) : null}
                  </RightBarDiv>
                )}
              </Grid>
            </Grid>
          </BodyDiv>
        </BodyDivWrapper>
        <AlertModal
          open={isModalOpen}
          responseCode=""
          setSuccessSubmit={() => setIsModalOpen(false)}
        >
          {isCopied ? "Raast ID Copied" : null}
        </AlertModal>
      </MainBody>
    </MainPageDiv>
  );
};

export default WrapperComponent;

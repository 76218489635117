// import { QueryClient } from "react-query";
// import {queryClient} from "../../src/index"

import { QueryClient } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 900000, // 15 minutes (in milliseconds)
    },
  },
});

export default queryClient;

import React from "react";
import { Box, Button, Modal } from "@mui/material";
import { FiLogOut } from "react-icons/fi";

interface Props {
  open?: boolean;
  onClose?: () => void;
  setSuccessSubmit?: () => void;
  children?: any;
}

const LogoutModal = ({ open, onClose, setSuccessSubmit, children }: Props) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "25%",
    bgcolor: "background.paper",
    borderRadius: "5px",
    border: "none",
    textAlign: "center",

    ":focus": {
      border: "none",
    },
  };
  if (window.innerWidth <= 768) {
    style.width = "80%";
  }
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <Modal
      open={open || false}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ px: 3, py: 2 }}>
          <Box>
            <FiLogOut size={50} color="green" />
          </Box>
          <Box
            sx={{
              paddingX: "10px",
              color: "#565d64",
              fontFamily: "FontRegular",
            }}
          >
            {children}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
            <Button
              sx={{
                width: "30%",
                backgroundColor: "green",
                color: "#fff",
                height: "42px",
                marginTop: "30px",
                textTransform: "capitalize",
                ":hover": {
                  backgroundColor: "limegreen",
                },
                fontFamily: "FontDemiBold",
              }}
              onClick={setSuccessSubmit}
            >
              Yes
            </Button>
            <Button
              sx={{
                width: "30%",
                backgroundColor: "#fff",
                color: "#000",
                height: "42px",
                marginTop: "30px",
                textTransform: "capitalize",
                ":hover": {
                  backgroundColor: "lightgray",
                },
                border: "1px solid black",
                fontFamily: "FontDemiBold",
              }}
              onClick={handleClose}
            >
              No
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default LogoutModal;

import React, { useState } from "react";
import {
  Logo,
  LogoContainer,
  SidebarItem,
  SidebarItemIcons,
  SidebarNav,
  SidebarWrap,
  Oval,
  InnerDiv,
} from "./styles";
import { sidebarProps } from "./types";
import { Capsule } from "../Capsule";
import { FaRegUserCircle } from "react-icons/fa";
import { BiDotsVerticalRounded } from "react-icons/bi";
import IconDots from "../../stories/assets/icons-line-12-more.svg";
import { GetLoginData } from "../../Api/Login";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import { Collapse, FormControlLabel, Grid, useMediaQuery } from "@mui/material";

// import nbpLogo from '../../stories/assets/images/nbpLogo.png';

const Sidebar = ({ menu, path, logoSrc, icon }: sidebarProps) => {
  const isMobile = useMediaQuery("(max-width:720px)");

  const loginData = GetLoginData();
  const [expandedMenu, setExpandedMenu] = useState<string | null>(null);

  const toggleSubnav = (menuTitle: string) => {
    if (expandedMenu === menuTitle) {
      setExpandedMenu(null);
    } else {
      setExpandedMenu(menuTitle);
    }
  };
  return (
    <SidebarNav>
      <div
        style={{
          height: "98vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          // position: "fixed",
        }}
      >
        <div>
          <LogoContainer>
            <Link to="/app">
              <Logo src="/assets/logo-dashboard.png" />
            </Link>
          </LogoContainer>
          <SidebarWrap>
            {menu.map((item, index) => (
              <SidebarItem key={index}>
                <Menu
                  menu={item}
                  key={index}
                  icon={icon}
                  subnav={expandedMenu === item.title} // Pass whether this menu should be expanded
                  toggleSubnav={toggleSubnav}
                />
              </SidebarItem>
            ))}
          </SidebarWrap>
        </div>
        <InnerDiv>
          <Oval>
            {loginData?.data?.data?.details?.accountTitle
              ?.split(" ")
              .slice(0, 2)
              .join(" ")
              .split(" ")
              .map((word: any) => word.charAt(0))
              .join("")}
          </Oval>
          <div
            style={{
              marginInlineEnd: "auto",
              fontSize: "10px",
              textTransform: "capitalize",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {
              loginData?.data?.data?.details?.accountTitle
              // ?.split(" ")
              // .slice(0, 2)
              // .join(" ")
            }
          </div>
          <img src={IconDots} alt="" />
        </InnerDiv>
      </div>
    </SidebarNav>
  );
};

export default Sidebar;
